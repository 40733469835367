import { BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./components/header/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";

import { IntlProvider } from "react-intl";
import messages_en from "./components/translations/en.json";
import messages_fr from "./components/translations/fr.json";
import messages_es from "./components/translations/es.json";
import ScrollTop from "./components/ScollTop";
import TranslationMissing from "./components/TranslationMissing";

const messages = {
  fr: messages_fr,
  en: messages_en,
  es: messages_es,
};

const App = () => {
  const langStore = useSelector((state) => state.lang);
  document.title =
    langStore.current_lang === "en"
      ? "Evaluation | TalenTest"
      : "Évaluation | TalenTest";
  
      document.documentElement.lang = langStore.current_lang;
  return (

      <IntlProvider
        locale={langStore.current_lang}
        messages={messages[langStore.current_lang]}
      >
        <BrowserRouter>
          <ScrollTop />
          <div id="app-wrapper" className="wrapper">
            <Header />
            <TranslationMissing />
            <Main />
            <Footer />
          </div>
        </BrowserRouter>
      </IntlProvider>
  );
}

export default App;
