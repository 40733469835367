import * as actionTypes from "../actions/assesment/actionTypes";
import * as authActionTypes from "../actions/auth/actionTypes";
import { combineReducers } from "redux";

export const assesment = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ASSESMENT_SUCCESSFUL:
    case actionTypes.UPDATE_ASSESMENT_SUCCESSFUL: {
          return {
            ...action.response,
            evaluation_session: {
              ...action.response.evaluation_session,
            },
          };
        }
    case authActionTypes.LOGIN_SUCCESSFUL: {
      return {
        ...state,
        id: action.response.assesment_id,
      };
    }  
    case authActionTypes.LOGOUT_REQUEST: {
      return null;
    }
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ASSESMENT_REQUEST:
      return true;
    case actionTypes.FETCH_ASSESMENT_SUCCESSFUL:
    case actionTypes.FETCH_ASSESMENT_FAILURE:
      return false;
    default:
      return state;
  }
};
const isUpdating = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ASSESMENT_REQUEST:
      return true;
    case actionTypes.UPDATE_ASSESMENT_SUCCESSFUL:
    case actionTypes.UPDATE_ASSESMENT_FAILURE:
      return false;
    default:
      return state;
  }
};
const error = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ASSESMENT_SUCCESSFUL:
    case actionTypes.UPDATE_ASSESMENT_SUCCESSFUL:
    case actionTypes.FETCH_ASSESMENT_REQUEST:
    case actionTypes.UPDATE_ASSESMENT_REQUEST:
      return null;
    case actionTypes.FETCH_ASSESMENT_FAILURE:
    case actionTypes.UPDATE_ASSESMENT_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const assesmentStatus = combineReducers({
  isFetching,
  isUpdating,
  error,
});
export default assesmentStatus;
export const getError = (state) => state.error;
export const getIsFetching = (state) => state.isFetching;
export const getIsUpdating = (state) => state.isUpdating;
