import React from "react";
import { useTranslation }  from 'hooks/useTranslation';
import { FormattedMessage } from "react-intl";
import Duration from "./Duration";

const TestItemRow = (props) => {
  const { assesment, assesmentTest } = props;
  const name = useTranslation("name", assesmentTest.test);
  return (
    <tr>
      <td>{name}</td>
      <td>
        <Duration assesment={assesment} assesmentTest={assesmentTest} />
      </td>
      <td>
        <FormattedMessage
          id="home.testItem.status"
          defaultMessage="--"
          values={{ completed: assesmentTest.completed_at !== null }}
        />
        <FormattedMessage
          id="home.testItem.in-person"
          defaultMessage=""
          values={{
            in_person:
            assesmentTest.dt_termine === null &&
            assesmentTest.assesment.currently_remote === true &&
            assesmentTest.test.remote === 0,
          }}
        />
      </td>
    </tr>
  );
}

export default TestItemRow;