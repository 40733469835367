import * as actions from "./actionTypes";

const resetTranslationMissingRequested = () => ({
  type: actions.RESET_TRANSLATION_MISSING_REQUEST,
});
export const resetTranslationMissing = () => (dispatch) => {
  dispatch(resetTranslationMissingRequested());
};

const setTranslationMissingRequested = (payload) => ({
  type: actions.SET_TRANSLATION_MISSING_REQUEST,
  payload,
});
export const setTranslationMissing = (payload) => (dispatch) => {
  dispatch(setTranslationMissingRequested(payload));
};
