import * as actionTypes from "../actions/auth/actionTypes";
import { combineReducers } from "redux";

const initialState = {
  isLoggedIn: false,
  api_token: undefined,
};

export const auth = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        clear_local_storage: true,
      };  
    case actionTypes.LOGIN_SUCCESSFUL:
      const { api_token } = action.response;
      return {
        ...state,
        isLoggedIn: true,
        api_token,
        clear_local_storage: false,
      };
    case actionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        isLoggedIn: false,
        api_token: undefined,
        clear_local_storage: true,
      };
    default:
      return state;
  }
};
const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
    case actionTypes.LOGOUT_REQUEST:
      return true;
    case actionTypes.LOGIN_SUCCESSFUL:
    case actionTypes.LOGIN_FAILURE:
    case actionTypes.LOGOUT_SUCCESSFUL:
    case actionTypes.LOGOUT_FAILURE:
      return false;
    default:
      return state;
  }
};
const error = (state = null, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_SUCCESSFUL:
    case actionTypes.LOGOUT_SUCCESSFUL:
    case actionTypes.LOGOUT_REQUEST:
    case actionTypes.LOGIN_REQUEST:
      return null;
    case actionTypes.LOGIN_FAILURE:
    case actionTypes.LOGOUT_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const authStatus = combineReducers({ isFetching, error });
export default authStatus;
export const getError = (state) => state.error;
export const getIsFetching = (state) => state.isFetching;
