import * as actions from "./actionTypes";

const fetchAssesmentTestRequested = () => ({
  type: actions.FETCH_ASSESMENT_TEST_REQUEST
});
const fetchAssesmentTestSuccessful = response => ({
  type: actions.FETCH_ASSESMENT_TEST_SUCCESSFUL,
  response
});
const fetchAssesmentTestFailure = error => ({
  type: actions.FETCH_ASSESMENT_TEST_FAILURE,
  error
});
export const fetchAssesmentTest = (id) => (dispatch, getState, api) => {
  dispatch(fetchAssesmentTestRequested());
  return new Promise(async (resolve, reject) => {
   await api.assesmentTest.get(id).then(
      response => {
        dispatch(fetchAssesmentTestSuccessful(response.data))
        resolve(response.data);
      },
      error => {
        dispatch(fetchAssesmentTestFailure(error));
        reject(error);
      }
    );
  });
};

const updateAssesmentTestElapsedTime = (id,time) => ({
  type: actions.UPDATE_ASSESMENT_TEST_ELAPSED_TIME,
  id,
  time
});
const updateAssesmentTestRequested = () => ({
  type: actions.UPDATE_ASSESMENT_TEST_REQUEST
});
const updateAssesmentTestSuccessful = response => ({
  type: actions.UPDATE_ASSESMENT_TEST_SUCCESSFUL,
  response
});
const updateAssesmentTestFailure = error => ({
  type: actions.UPDATE_ASSESMENT_TEST_FAILURE,
  error
});
export const updateAssesmentTest = (id, payload) => (
  dispatch,
  getState,
  api
) => {
  dispatch(updateAssesmentTestRequested());
  return new Promise(async (resolve, reject) => {
    await api.assesmentTest.put(id, payload).then(
      response => {
        dispatch(updateAssesmentTestSuccessful(response.data));
        resolve(response.data);
      },
      error => {
        dispatch(updateAssesmentTestFailure(error));
        reject(error);
      }
    );
  });
};
export const usePrevTestAnswers = (id_user_session, id_test, payload) => (
  dispatch,
  getState,
  api
) => {
  dispatch(updateAssesmentTestRequested());
  return new Promise(async (resolve, reject) => {
    await api.assesmentTest.usePrevAnswers(id_user_session, id_test, payload)
      .then(
        response => {
          dispatch(updateAssesmentTestSuccessful(response.data));
          resolve(response.data);
        },
        error => {
          dispatch(updateAssesmentTestFailure(error));
          reject(error);
        }
      );
  });
};
export const markAssesmentTestAsCompleted = (id, payload) => (
  dispatch,
  getState,
  api
) => {
  dispatch(updateAssesmentTestRequested());
  return new Promise(async (resolve, reject) => {
    await api.assesmentTest.completed(id, payload).then(
      response => {
        dispatch(updateAssesmentTestSuccessful(response.data));
        resolve(response.data);
      },
      error => {
        dispatch(updateAssesmentTestFailure(error));
        reject(error);
      }
    );
  });
};
export const updateAssesmentTestElapsedTimeLocally = (
  id,
  time
) => dispatch => {
  dispatch(updateAssesmentTestElapsedTime(id,time));
  return true;
};
