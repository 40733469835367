import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import DatePickerSelect from "../helpers/date-picker-select";

class LayoutAcademic10 extends Component {
  render() {
    const {
      candidate,
      assesment,
      handleInputChange,
      handleDateChange,
      sex,
      lang,
      submit,
      agreed,
      readNunderstood,
      cancel,
    } = this.props;
    return (
      <div id="consent" className="page">
        <section>
          <div id="consent-content">
            <div>
              <FormattedMessage
                id="consent.academic-10.title"
                defaultMessage="Autorisation à l'évaluation"
              >
                {(txt) => <h1>{txt}</h1>}
              </FormattedMessage>
              <FormattedMessage
                id="consent.academic-10.intro"
                defaultMessage=""
              >
                {(txt) => <p>{txt}</p>}
              </FormattedMessage>
              <form className="form-vertical">
                <fieldset>
                  <FormattedMessage
                    id="consent.academic-10.title.form"
                    defaultMessage=""
                  >
                    {(txt) => <h3>{txt}</h3>}
                  </FormattedMessage>
                  <FormattedMessage
                    id="app.global.label.name"
                    defaultMessage=""
                  >
                    {(txt) => (
                      <label>
                        {txt}
                        <FormattedMessage id="app.global.semi-colon" />
                      </label>
                    )}
                  </FormattedMessage>
                  <div className="input-row">
                    <input
                      type="text"
                      value={candidate.first_name}
                      disabled={true}
                    />
                    <input type="text" value={candidate.last_name} disabled={true} />
                  </div>
                  <FormattedMessage
                    id="app.global.label.gender"
                    defaultMessage=""
                  >
                    {(txt) => (
                      <label>
                        {txt}
                        <FormattedMessage id="app.global.semi-colon" />
                      </label>
                    )}
                  </FormattedMessage>
                  <select
                    name="sex"
                    id="sex"
                    onChange={handleInputChange}
                    value={sex}
                  >
                    <option value="">--</option>
                    <FormattedMessage id="app.global.label.female">
                      {(txt) => <option value="F">{txt}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="app.global.label.male">
                      {(txt) => <option value="M">{txt}</option>}
                    </FormattedMessage>
                    <FormattedMessage id="app.global.label.other">
                      {(txt) => <option value="O">{txt}</option>}
                    </FormattedMessage>
                  </select>
                  <FormattedMessage id="app.global.label.dob" defaultMessage="">
                    {(txt) => (
                      <label>
                        {txt}
                        <FormattedMessage id="app.global.semi-colon" />
                      </label>
                    )}
                  </FormattedMessage>
                  <DatePickerSelect
                    id="dob_select"
                    date={candidate.dob}
                    onChange={handleDateChange}
                  />
                </fieldset>
              </form>
              <FormattedMessage
                id="consent.academic-10.during-passation-I"
                defaultMessage=""
              >
                {(txt) => <h5>{txt}</h5>}
              </FormattedMessage>
              <ol>
                <FormattedMessage id="consent.academic-10.p1" defaultMessage="">
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
                <FormattedMessage
                  id="consent.academic-10.p2"
                  defaultMessage=""
                  values={{ demandeur: assesment.evaluation_session.team?.name[lang.current_lang] }}
                >
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
                <FormattedMessage id="consent.academic-10.p3" defaultMessage="">
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
                <FormattedMessage id="consent.academic-10.p4">
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
                <FormattedMessage id="consent.academic-10.p5">
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
                <FormattedMessage
                  id="consent.academic-10.p6"
                  defaultMessage=""
                  values={{
                    partenaire: assesment.evaluation_session.team?.partner?.name ?? "e-Potentiel",
                  }}
                >
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
                <FormattedMessage id="consent.academic-10.p7" defaultMessage="">
                  {(txt) => <li>{txt}</li>}
                </FormattedMessage>
              </ol>
            </div>

            <ul className="no-bullet vertical">
              <li className="text-center">
                <label
                  id="agreement_chkb-label"
                  htmlFor="agreement_chkb"
                  className="inline"
                >
                  <input
                    id="agreement_chkb"
                    name="agreed"
                    value="true"
                    type="checkbox"
                    checked={agreed}
                    onChange={handleInputChange}
                  />
                  <FormattedMessage id="consent.academic-10.label.I-accept" />
                </label>
              </li>
              <li className="text-center">
                <label
                  id="readNunderstood_chkb-label"
                  htmlFor="readNunderstood_chkb"
                  className="inline"
                >
                  <input
                    id="readNunderstood_chkb"
                    name="readNunderstood"
                    value="true"
                    type="checkbox"
                    checked={readNunderstood}
                    onChange={handleInputChange}
                  />
                  <FormattedMessage
                    id="consent.academic-10.label.I-read-understood"
                    values={{
                      link: <a href={process.env.PUBLIC_URL + `/files/terms_${lang.current_lang ?? "en"}.html`} target="_blank"><FormattedMessage id="consent.academic-10.user-agreements.title" /></a>
                    }}
                  />
                </label>
              </li>
              <li id="ok-btn-wrapper" className="text-center">
                <button
                  className="btn btn-primary"
                  onClick={submit}
                  disabled={!agreed || !readNunderstood}
                >
                  <FormattedMessage id="app.global.button.continue" />
                </button>
              </li>
              <li id="cancel-btn-wrapper" className="text-center">
                <button className="btn btn-link" onClick={cancel}>
                  <FormattedMessage id="app.global.button.cancel" />
                </button>
              </li>
            </ul>
          </div>
        </section>
        {this.props.children}
      </div>
    );
  }
}
export default LayoutAcademic10;
