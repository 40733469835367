export const FETCH_ASSESMENT_TEST_QUESTION_REQUEST     = "FETCH_ASSESMENT_TEST_QUESTION_REQUEST";
export const FETCH_ASSESMENT_TEST_QUESTION_SUCCESSFUL  = "FETCH_ASSESMENT_TEST_QUESTION_SUCCESSFUL";
export const FETCH_ASSESMENT_TEST_QUESTION_FAILURE     = "FETCH_ASSESMENT_TEST_QUESTION_FAILURE";

export const UPDATE_ASSESMENT_TEST_QUESTION_REQUEST     = "UPDATE_ASSESMENT_TEST_QUESTION_REQUEST";
export const UPDATE_ASSESMENT_TEST_QUESTION_SUCCESSFUL  = "UPDATE_ASSESMENT_TEST_QUESTION_SUCCESSFUL";
export const UPDATE_ASSESMENT_TEST_QUESTION_FAILURE     = "UPDATE_ASSESMENT_TEST_QUESTION_FAILURE";

export const LOAD_IMG_ASSESMENT_TEST_QUESTION_REQUEST     = "LOAD_IMG_ASSESMENT_TEST_QUESTION_REQUEST";
export const LOAD_IMG_ASSESMENT_TEST_QUESTION_SUCCESSFUL  = "LOAD_IMG_ASSESMENT_TEST_QUESTION_SUCCESSFUL";
export const LOAD_IMG_ASSESMENT_TEST_QUESTION_FAILURE     = "LOAD_IMG_ASSESMENT_TEST_QUESTION_FAILURE";