import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { assesment as assesmentApi } from "../../api/Api";
import * as assesmentActions from "../../redux/actions/assesment/index";
import * as assesmentTestActions from "../../redux/actions/assesmentTest/index";
import * as candidateActions from "../../redux/actions/candidate/index";
import * as fromCandidateReducer from "../../redux/reducers/candidate";
import * as fromAssesmentReducer from "../../redux/reducers/assesment";
import * as fromAssesmentTestReducer from "../../redux/reducers/assesmentTest";
import LoadingScreen from "../helpers/loadingScreen";
import PreviouslyCompletedTest from "./PreviouslyCompletedTest";
import FetchError from "../error/FetchError";
import Header from "./intro/Header";
import Instructions from "./intro/Instructions";
import Example from "./intro/Example";
import StartButton from "./intro/StartButton";

class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assesmentTest: undefined,
    };

    this.usePreviousAnswers = this.usePreviousAnswers.bind(this);
    this.refreshHandler = this.refreshHandler.bind(this);
    this.fetchData = this.fetchData.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchData();
  }

  async fetchData() {
    const { fetchCandidate, fetchAssesment } = this.props.actions;

    fetchCandidate();
    await fetchAssesment(this.props.assesment.id);
    await assesmentApi.getNextAssesmentTest(this.props.assesment.id).then((resp) => {
      this.setState({
        assesmentTest: resp.data,
      });
    }).catch((error) => {
      // if status 404, no more tests
      if (error.response && error.response.status === 404) {
        this.props.history.push("/conclusion");
      }
    });
  }

  usePreviousAnswers() {
    // TODO: Test this with a real previous assesment test id
    const { usePrevTestAnswers } = this.props.actions;

    const assesmentTest = this.state.assesmentTest;
    const previouslyCompleted = this.state.assesmentTest.previouslyCompleted;

    usePrevTestAnswers(assesmentTest.id, {
      previous_assesment_test_id: previouslyCompleted.id,
    }).then(() => {
      this.setState(
        {
          assesmentTest: undefined,
        },
        () => {
          this.fetchData();
        }
      );
    });
  }

  // Called when API automatically used previous answwers (e.g. Chrono test completed less than 30 days ago)
  refreshHandler() {
    this.setState(
      {
        assesmentTest: undefined,
      },
      () => {
        this.fetchData();
      }
    );
  }

  render() {
    const {
      candidate,
      candidate_isFetching,
      candidate_error,
      assesment,
      assesment_isFetching,
      assesment_error,
    } = this.props;

    const { assesmentTest } = this.state;

    if (candidate_error) {
      return <FetchError error={candidate_error} redirect={true} />;
    }

    if (assesment_error) {
      return <FetchError error={assesment_error} redirect={true} />;
    }

    if (candidate_isFetching || assesment_isFetching) {
      return <LoadingScreen />;
    }

    if (
      assesment &&
      assesment.id &&
      !assesment.consent
    ) {
      // No consent (mandatory)
      return <Redirect to={"/consentement"} />;
    }

    // Test previously completed?
    if (assesmentTest && assesmentTest.previouslyCompleted) {
      return (
        <PreviouslyCompletedTest
          assesmentTest={assesmentTest}
          previousAssesmentTest={assesmentTest.previouslyCompleted}
          assesment={assesment}
          refreshHandler={this.refreshHandler}
          usePreviousHandler={this.usePreviousAnswers}
          answerAgainHandler={() => {
            // Set previouslyCompleted to null...
            this.setState((prevState) => ({
              assesmentTest: {
                ...prevState.assesmentTest,
                previouslyCompleted: null,
              },
            }));
          }}
        />
      );
    }

    if (assesmentTest) {
      return (
        <div id='intro' className='page'>
          <section>
            <Header
              assesment={assesment}
              assesmentTest={assesmentTest}
              assesmentTests={assesment.assesment_tests}
              remote={assesment.currently_remote}
            />
            <Instructions assesment={assesment} assesmentTest={assesmentTest} />
            <Example test={assesmentTest.test} />
            <StartButton assesmentTestId={assesmentTest.id} />
          </section>
        </div>
      );
    }

    return <LoadingScreen />;
  }
}

function mapStateToProps(state) {
  return {
    candidate: state.candidate,
    candidate_isFetching: fromCandidateReducer.getIsFetching(
      state.candidateStatus
    ),
    candidate_error: fromCandidateReducer.getError(state.candidateStatus),
    assesment: state.assesment,
    assesment_isFetching: fromAssesmentReducer.getIsFetching(
      state.assesmentStatus
    ),
    assesment_error: fromAssesmentReducer.getError(
      state.assesmentStatus
    ),
    assesmentTest_isFetching: fromAssesmentTestReducer.getIsFetching(
      state.assesmentTestStatus
    ),
    assesmentTest_error: fromAssesmentTestReducer.getError(
      state.assesmentTestStatus
    ),
  };
}

function mapDispatchToProps(dispatch) {
  // this.props.actions
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        candidateActions,
        assesmentTestActions,
        assesmentActions
      ),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Test);
