import { FormattedMessage, useIntl } from "react-intl";

const DemoProfessionalConclusion = (props) => {
  const intl = useIntl();
  
  return (
    <section id="demo-professional-test-conclusion">
      
      <h1>Conclusion</h1>

      <p><FormattedMessage id="demo.professional.conclusion.p1" /></p>
      
      <p className="margin-bottom-none"><FormattedMessage id="demo.professional.conclusion.p2" /></p>
      <ul className="bullet ul-compact">
        <li><FormattedMessage id="demo.professional.conclusion.ul.li1" /></li>
        <li><FormattedMessage id="demo.professional.conclusion.ul.li2" /></li>
        <li><FormattedMessage id="demo.professional.conclusion.ul.li3" /></li>
        <li>
          <FormattedMessage
            id="demo.professional.conclusion.ul.li4"
            values={
              {
                Important : <strong>{intl.formatMessage({id: "demo.professional.conclusion.ul.li4_important"})}</strong>,
                ZoomSectionLink: <a href={`https://evaluation.e-potentiel.com/faq/${props.pref_lang}`}>{intl.formatMessage({id: "demo.professional.conclusion.ul.li4_zoomSectionLink"})}</a>,
                FAQLink: <a href={`https://evaluation.e-potentiel.com/faq/${props.pref_lang}`}>{intl.formatMessage({id: "demo.professional.conclusion.FAQLink"})}</a>
              }
            }
          />
        </li>
      </ul>

      <p><FormattedMessage id="demo.professional.conclusion.p3" values={
        {FAQLink: <a href={`https://evaluation.e-potentiel.com/faq/${props.pref_lang}`}>{intl.formatMessage({id: "demo.professional.conclusion.FAQLink"})}</a>}
      } /></p>

      <p><FormattedMessage id="demo.professional.conclusion.p4" values={{
        StartOverButton: <strong>{intl.formatMessage({id: "demo.professional.conclusion.startOverButton"})}</strong>
      }} /></p>

      <div className="text-center">
        <button className="bnt btn-primary btn-lg" onClick={()=>props.next()}><FormattedMessage id="demo.professional.conclusion.startOverButton" /></button>
      </div>
    </section>
  );
};

export default DemoProfessionalConclusion;