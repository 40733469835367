import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

class LayoutAcademic10 extends Component {
  render() {
    const { testCompletedHandler } = this.props;
    return (
      <div className="page">
        <section>
          <h1>
            <FormattedMessage id="questionnaire.completed.academic10.title" />
          </h1>
          <p>
            <FormattedMessage id="questionnaire.completed.academic10.p1" />
          </p>
          <p>
            <FormattedMessage
              id="questionnaire.completed.academic10.p2"
              values={
                {Previous: <strong>{this.props.intl.formatMessage({id: "questionnaire.completed.academic10.p2_previous"})}</strong>}
              } 
            />
          </p>
          <p>
            <FormattedMessage
              id="questionnaire.completed.academic10.p3"
              values={
                {Submit: <strong>{this.props.intl.formatMessage({id: "questionnaire.completed.academic10.p3_submit"})}</strong>}
              }
            />
          </p>
          <p>
            <FormattedMessage
              id="questionnaire.completed.academic10.p4"
              values={
                {Important:<strong>{this.props.intl.formatMessage({id: "questionnaire.completed.academic10.p4_important"})}</strong>}
              }
            />
          </p>
          <button className="btn-lg btn-primary" onClick={testCompletedHandler}>
            <FormattedMessage id="questionnaire.completed.academic10.button.submit_questionnaire" />
          </button>
        </section>
      </div>
    );
  }
}

export default injectIntl(LayoutAcademic10);
