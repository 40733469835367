import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as assesmentTestQuestionActions from "../../../../redux/actions/assesmentTestQuestion/index";

const Image = (props) => {
  const { current } = props; // current refers to the current assesment_test_question
  const langStore = useSelector(state => state.lang);
  const dispatch = useDispatch();
  const imagePath = current.question.image[langStore.current_lang] ?? null;

  useEffect(()=>{
    if(imagePath){
      dispatch(assesmentTestQuestionActions.loadImgAssementTestQuestionRequested(current.id));
    }else {
      dispatch(assesmentTestQuestionActions.loadImgAssementTestQuestionSuccessful(current.id));
    }
  },[current.id])
  
  if(!imagePath){
    return (null);
  }

  return (
    <div id="image-wrapper">
      <img 
        className="question-image"
        src={imagePath}
        alt="Image for the question"
        onLoad={()=>dispatch(assesmentTestQuestionActions.loadImgAssementTestQuestionSuccessful(current.id))}
        onError={(error)=>dispatch(assesmentTestQuestionActions.loadImgAssementTestQuestionFailure(current.id, error))}
      /> 
    </div>
  )
}

export default Image;