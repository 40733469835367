import React from "react";
import OptionItem from "./OptionItem";

function OptionItems(props) {
  const { current, answer, onChange } = props;

  return (
    <div id="right-col">
      <div id="options-wrapper" className="options radio-group">
        {current.question.answer_definition.answer_definition_choices.map((option) => {
          return (
            <div key={option.id} className="form-item">
              <label
                htmlFor={"option-" + option.id}
                className={answer?.includes(option.id) ? "selected" : null}
              >
                <input
                  id={"option-" + option.id}
                  type="radio"
                  name="answer"
                  value={option.id}
                  checked={answer?.includes(option.id)}
                  onChange={onChange}
                />
                <OptionItem option={option} />
                {option.answer_image_id ? (
                  <img
                    src={"/images/questions/" + option.answer_image_id + ".png"}
                    alt=""
                  />
                ) : (
                  ""
                )}
              </label>
            </div>
          );
        })}
      </div>
      {props.children}
    </div>
  );
}

export default OptionItems;
