import React, { Component } from "react";
import * as AUDIENCES from 'constants/audiences';
import * as candidateActions from "../../redux/actions/candidate/index";
import * as assesmentActions from "../../redux/actions/assesment/index";
import * as fromCandidateReducer from "../../redux/reducers/candidate";
import * as fromAssesmentReducer from "../../redux/reducers/assesment";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import LoadingScreen from "../helpers/loadingScreen";
import FetchError from "../error/FetchError";
import LayoutProfessional from "./LayoutProfessional";
import LayoutAcademic10 from "./LayoutAcademic-10";
import LayoutCollege20 from "./LayoutCollege-20";
import { getConsentHTML, getValidDate } from "./helpers/helpers";

class Consent extends Component {
  constructor(props) {
    super(props);
    const { sex } = this.props.candidate;
    this.state = {
      agreed: false,
      readNunderstood: false,
      sex,
      year: undefined,
      month: undefined,
      day: undefined,
    };

    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchData();
  }

  fetchData() {
    const { fetchCandidate, fetchAssesment } = this.props.actions;

    fetchCandidate();
    fetchAssesment(this.props.assesment.id);
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleDateChange(year, month, day) {
    this.setState({
      year: year,
      month: month,
      day: day,
    });
  }

  submit = (e) => {
    e.preventDefault();
    const { updateCandidate, updateAssesment } = this.props.actions;
    const { candidate, assesment } = this.props;

    // This should not happen since submit button should be disabled if not agreed
    if (!this.state.agreed || !this.state.readNunderstood) {
      alert(this.props.intl.formatMessage({ id: "consent.not-agreed" }));
      return;
    }

    const dob = getValidDate(this.state.year, this.state.month, this.state.day);
    if (dob === false) {
      alert(this.props.intl.formatMessage({ id: "consent.invalid-dob" }));
      return;
    }

    // Update candidate and assesment
    updateCandidate(candidate.id, {
      sex: this.state.sex ? this.state.sex : "O", // Defaults to "O" if not set
      dob: !dob
        ? null
        : dob.toLocaleDateString("fr-CA", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }),
    }).then(() => {
      updateAssesment(assesment.id, {
        consentement: getConsentHTML(
          this.state.year,
          this.state.month,
          this.state.day,
          this.state.sex
        ),
      }).then(() => {
        this.props.history.push("/assesment-test");
      });
    });
  };

  render() {
    const {
      candidate,
      candidate_isFetching,
      candidate_isUpdating,
      candidate_error,
      assesment,
      assesment_isFetching,
      assesment_isUpdating,
      assesment_error,
    } = this.props;

    if (candidate_isFetching || assesment_isFetching) {
      return <LoadingScreen />;
    }

    if (candidate_error) {
      return <FetchError error={candidate_error} redirect={true} />;
    }

    if (assesment_error) {
      return <FetchError error={assesment_error} redirect={true} />;
    }

    if (
      assesment &&
      assesment.evaluation_session &&
      assesment.evaluation_session.audience_type_id === AUDIENCES.ACADEMIC_PRIMARY
    ) {
      return (
        <LayoutAcademic10
          candidate={candidate}
          assesment={assesment}
          handleInputChange={this.handleInputChange}
          handleDateChange={this.handleDateChange}
          sex={this.state.sex}
          submit={this.submit}
          agreed={this.state.agreed}
          readNunderstood={this.state.readNunderstood}
          lang={this.props.lang}
          cancel={() => this.props.history.push("/")}
        >
          {(candidate_isUpdating || assesment_isUpdating) && (
            <LoadingScreen />
          )}
        </LayoutAcademic10>
      );
    }

    if (
      assesment &&
      assesment.evaluation_session &&
      assesment.evaluation_session.audience_type_id === AUDIENCES.ACADEMIC_COLLEGE
    ) {
      return (
        <LayoutCollege20
          candidate={candidate}
          assesment={assesment}
          handleInputChange={this.handleInputChange}
          handleDateChange={this.handleDateChange}
          sex={this.state.sex}
          submit={this.submit}
          agreed={this.state.agreed}
          readNunderstood={this.state.readNunderstood}
          lang={this.props.lang}
          cancel={() => this.props.history.push("/")}
        >
          {(candidate_isUpdating || assesment_isUpdating) && (
            <LoadingScreen />
          )}
        </LayoutCollege20>
      );
    }

    if (
      assesment &&
      assesment.evaluation_session &&
      assesment.evaluation_session.audience_type_id === AUDIENCES.PROFESSIONAL
    ) {
      return (
        <LayoutProfessional
          candidate={candidate}
          assesment={assesment}
          handleInputChange={this.handleInputChange}
          handleDateChange={this.handleDateChange}
          sex={this.state.sex}
          submit={this.submit}
          agreed={this.state.agreed}
          readNunderstood={this.state.readNunderstood}
          lang={this.props.lang}
          cancel={() => this.props.history.push("/")}
        >
          {(candidate_isUpdating || assesment_isUpdating) && (
            <LoadingScreen />
          )}
        </LayoutProfessional>
      );
    }

    return <LoadingScreen />;
  }
}

function mapStateToProps(state) {
  return {
    candidate: state.candidate,
    candidate_error: fromCandidateReducer.getError(state.candidateStatus),
    candidate_isFetching: fromCandidateReducer.getIsFetching(
      state.candidateStatus
    ),
    candidate_isUpdating: fromCandidateReducer.getIsUpdating(
      state.candidateStatus
    ),
    assesment: state.assesment,
    assesment_error: fromAssesmentReducer.getError(
      state.assesmentStatus
    ),
    assesment_isFetching: fromAssesmentReducer.getIsFetching(
      state.assesmentStatus
    ),
    assesment_isUpdating: fromAssesmentReducer.getIsUpdating(
      state.assesmentStatus
    ),
    lang: state.lang,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, candidateActions, assesmentActions),
      dispatch
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(Consent));
