const DEFAULT_REMAINING_TIME = "00:00";

/**
 * Formats the remaining time in seconds into a human-readable format.
 *
 * @param {number} remaining - The remaining time in seconds.
 * @returns {string} The formatted remaining time in the format HH:MM:SS.
 */
export const formatRemainingTime = (remaining) => {
  if (remaining > 0) {
    return new Date(remaining * 1000).toISOString().substring(11, 19);
  }
  return DEFAULT_REMAINING_TIME; // Handle negative values
}