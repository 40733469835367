import React from 'react';
import DropZone from './DropZone';

const DropZones = ({items, baskets, save}) => {
  const getDropZonesItems = (value) => {
      return items.filter(item => item.answers.includes(value))  
  }

  return(
    <div id="drop-zones-wrapper">
      { baskets.map(
        (dropzone, index) => (
          <DropZone
            key={'drop-zone-'+index}
            dropzone={dropzone}
            value={dropzone.answer_definition_choice.id}
            items={getDropZonesItems(dropzone.answer_definition_choice.id)}
            max={dropzone.item_count}
            save={save} />
        )
      ) }
    </div>
  )
}

export default DropZones