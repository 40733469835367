export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState).state;
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  if (state)
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem("state", serializedState);
    } catch (err) {
      console.log("LocaStorage Error!");
      // Ignore write errors.
    }
};

export const clearState = () => {
  try {
    localStorage.clear();
  } catch (err) {
    console.log("LocaStorage Error!");
    // Ignore write errors.
  }
};
