import React, { Component } from "react";
import { Link } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { helpers } from "../helpers/helpers";
import TestItemsTable from "./TestItemsTable";

class LayoutProfessional extends Component {
  render() {
    const { candidate, assesment, lang, onStartBtnClicked } =
      this.props;
    const localized_eprofil =
      lang.current_lang === "en" ? "TalenTest" : "TalenTest";

    const SelectLang = () => {
      const intl = useIntl();
      return (
        <select
          id="test-lang-select"
          name="test_lang"
          className=""
          defaultValue={lang.current_lang}
        >
          <option value="fr">
            {helpers.capitalize(
              intl.formatMessage({ id: "app.global.french" })
            )}
          </option>
          <option value="en">
            {helpers.capitalize(
              intl.formatMessage({ id: "app.global.english" })
            )}
          </option>
          <option value="es">
            {helpers.capitalize(
              intl.formatMessage({ id: "app.global.spanish" })
            )}
          </option>
        </select>
      );
    };

    return (
      <div id="home" className="page">
        <section>
          <div>
            <h1>
              <FormattedMessage id="home.professional.title_1" />
              <br />
              <FormattedMessage id="home.professional.title_2" />
            </h1>
            <p>
              <FormattedMessage
                id="home.professional.intro.p1"
                values={{ name: candidate.first_name }}
              />
            </p>
            <p>
              <FormattedMessage id="home.professional.intro.p2" />
            </p>
            <p>
              <FormattedMessage
                id="home.professional.intro.p3"
                values={{
                  demandeur: <strong>{assesment.evaluation_session.team?.name[lang.current_lang]}</strong>,
                  prefix_demandeur_fr: helpers.isApostropheNeeded(
                    String(assesment.evaluation_session.team?.name[lang.current_lang])
                  )
                    ? "d'"
                    : "de ",
                  is_female: candidate.sex === "F",
                }}
              />
            </p>
            <p>
              <FormattedMessage
                id="home.professional.intro.p4"
                values={{ is_female: candidate.sex === "F" }}
              />
            </p>
            <p>
              <FormattedMessage id="home.professional.intro.p5" />
            </p>
            <p>
              <FormattedMessage
                id="home.professional.intro.p6"
                values={{
                  partenaire: localized_eprofil,
                  prefix_partenaire_fr: helpers.isApostropheNeeded(
                    String("TalenTest")
                  )
                    ? "d'"
                    : "de ",
                }}
              />
            </p>
          </div>

          <div>
            <h3>
              <FormattedMessage id="home.professional.title.questionnaires" />
            </h3>
            <p>
              <FormattedMessage
                id="home.professional.questionnaires-list"
                values={{ is_adapt: Boolean(assesment.candidate_adapted_measures_factor) }}
              />
            </p>
            {assesment &&
              <TestItemsTable assesment={assesment} />
            }
          </div>
          {(!lang.forced_lang) ? (
            <div>
              <h3>
                <FormattedMessage id="home.title.before-we-start" />
              </h3>
              <form>
                <div className="full-width">
                  <label htmlFor="test_lang">
                    <FormattedMessage id="home.label.select-lang" />
                  </label>
                  <SelectLang />
                </div>
              </form>
            </div>
          ) : null }
          <div className="text-center">
            <Link
              className="btn btn-primary btn-lg"
              to="/consentement"
              onClick={onStartBtnClicked}
            >
              <FormattedMessage id="app.global.button.start" />
            </Link>
          </div>
        </section>
      </div>
    );
  }
}

export default LayoutProfessional;
