import { FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";

function Row(props) {
  const { attachment } = props;
  if (!attachment.url) return <></>;

  return (
    <a href={attachment.url} target="_blank">
      {attachment.file_name}
    </a>
  );
}

const Attachments = (props)=>{
  const { attachments } = props;
  const lang = useSelector((state) => state.lang);

  if(!attachments || !attachments[lang.current_lang] || attachments[lang.current_lang].length <= 0){
    return <></>;
  }

  return (
    <div id="attachments" className="table-wrapper">
      <h4>
        <FormattedMessage id="user-test.attachments.title" />
      </h4>
      <table className="table-listing">
        <thead>
          <tr>
            <th>
              <FormattedMessage id="user-test.attachments.file" />
            </th>
          </tr>
        </thead>
        <tbody>
          {attachments[lang.current_lang].map((attachment, index) => (
            <tr key={index}>
              <td>
                <Row attachment={attachment} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Attachments;
