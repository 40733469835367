import * as actionTypes from "../actions/translation/actionTypes";

const initialState = {
  is_missing: false,
  lang_used: null,
  lang_missing: null,
  key: null,
};
export const translation = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_TRANSLATION_MISSING_REQUEST:
      return { ...state, ...action.payload };
    case actionTypes.RESET_TRANSLATION_MISSING_REQUEST:
      return { ...initialState };
    default:
      return state;
  }
};
