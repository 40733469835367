import React, { useState, useEffect } from 'react';
import * as authActions  from "../../redux/actions/auth/index";
import { FormattedMessage } from "react-intl";
import LoadingScreen from "../helpers/loadingScreen";
import { Redirect } from 'react-router';
import { clearState } from "../../localStorage";
import { useSelector, useDispatch } from 'react-redux';

const AssesmentLogin = (props) => {

  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const [initialized, setInitialized] = useState(false);
  const [auth_error, setAuthError] = useState(null);
  
  const signedUrl = props.match.params.signedUrl + props.location.search;
  
  useEffect(() => {
    clearState();
    dispatch(authActions.logout());
    dispatch(authActions.doAssesmentLogin(signedUrl))
      .then(()=>setInitialized(true))
      .catch((error)=>{
        console.log(error);
        setAuthError(error);
      });
  }, []);


    // TODO: Make sure API returns error "too_early" when the user tries to login too early
    if(auth_error && auth_error.response?.data?.error === "too_early"){
      return (
        <>
          <h1>
            <FormattedMessage id='code-perm-login.title.too-early' />
          </h1>
          <p>
            <FormattedMessage id='code-perm-login.text.too-early.p1' />
          </p>
          {/* TODO: Make sure API returns dt_debut and dt_fin */}
          {auth_error.response?.data?.dt_debut && auth_error.response?.data?.dt_fin &&
          <p>
            <FormattedMessage id='code-perm-login.text.too-early.p2' values={{Start: auth_error.response.data.dt_debut, End: auth_error.response.data.dt_fin}} />
          </p>
          }
        </>
      );
    }else if(auth_error){
      return(
      <p>
        <FormattedMessage id='code-perm-login.text.no-time-slot' />
      </p>
      );
    }


    if(initialized && auth?.isLoggedIn){
      return <Redirect to="/" />
    }

    return <LoadingScreen />
  }

  export default AssesmentLogin;