import React from "react";
import { useTranslation } from "hooks/useTranslation";

function QuestionInstructions(props) {
  const { current } = props; // current here refers to the current assesment_test_question
  const questionInstructions = useTranslation("instructions", current.question.answer_definition) 

  return (
    <div id="question-instructions">
      <p>
        { questionInstructions }
      </p>
    </div>
  );
}

export default QuestionInstructions;