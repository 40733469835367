import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { FormattedMessage } from "react-intl";

class FetchError extends Component {
  getErrorMessage(error) {
    try {
      if (error.code === "ECONNABORTED") return "error.api-timeout";
      if (error.response.status === 401) return "error.token-expired";
      if (error.response.status === 500) return "error.server-error";
      return "error.error";
    } catch {
      return "error.error";
    }
  }

  is(status, error) {
    try {
      return error.response.status === status;
    } catch {
      return false;
    }
  }

  render() {
    const { error, redirect = false } = this.props;

    if (this.is(401, error) && redirect) {
      return <Redirect to="/connexion-requise" />;
    }

    return (
      <div id="error" className="page">
        <section>
          <h1>
            <FormattedMessage id="error.oops" defaultMessage="Oups" />
          </h1>
          <p>
            <FormattedMessage
              id={this.getErrorMessage(error)}
              defaultMessage="Erreur"
            />
          </p>
          <button
            id="retry-btn"
            className="btn btn-primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            <FormattedMessage id="app.global.button.retry" />
          </button>
        </section>
      </div>
    );
  }
}

export default FetchError;
