import * as actions from "./actionTypes";

const fetchCandidateRequested = () => ({
    type: actions.FETCH_CANDIDATE_REQUEST,
})
const fetchCandidateSuccessful = (response) => (dispatch) => {
    dispatch({
      type: actions.FETCH_CANDIDATE_SUCCESSFUL,
      response,
      asyncDispatch: dispatch,
    });
  };
const fetchCandidateFailure = (error) => ({
    type: actions.FETCH_CANDIDATE_FAILURE,
    error
})
export const fetchCandidate = () => (dispatch, getState, api) => {
    dispatch(fetchCandidateRequested());
    return new Promise(async (resolve, reject) => {
        await api.candidate.get().then(
            response => {
                dispatch(fetchCandidateSuccessful(response.data));
                resolve(response.data);
            },
            error => {
                dispatch(fetchCandidateFailure(error));
                reject(error);
            }
        );
    });
}


const updateCandidateRequested = () => ({
    type: actions.UPDATE_CANDIDATE_REQUEST,
})
const updateCandidateSuccessful = (response) => ({
    type: actions.UPDATE_CANDIDATE_SUCCESSFUL,
    response
})
const updateCandidateFailure = (error) => ({
    type: actions.UPDATE_CANDIDATE_FAILURE,
    error
})
export const updateCandidate = (id, payload) => (dispatch, getState, api) => {
    dispatch(updateCandidateRequested());
    return new Promise(async (resolve, reject) => {
        await api.candidate.put(id, payload).then(
            response => {
                dispatch(updateCandidateSuccessful(response.data));
                resolve(response.data);
            },
            error => {
                dispatch(updateCandidateFailure(error));
                reject(error);
            }
        );
    });
}