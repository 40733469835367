import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { helpers } from "../helpers/helpers";
import Header from "./intro/Header";

const PreviouslyCompletedTest = (props) => {
  const {
    assesmentTest,
    previousAssesmentTest,
    assesment,
    refreshHandler,
    usePreviousHandler,
    answerAgainHandler
  } = props;
  const isTestChronoCompletedLessThan30DaysAgo = ()=>{
    // When this happens, API return completed_at with now()
    return (
      props.assesmentTest.automatically_completed === true &&
      props.assesmentTest.automatically_completed_reason ===
      "Timed test was completed less than 30 days ago."
      );
    }
    
    const lang = useSelector((state) => state.lang);
    const getLocaleDateString = () => {
      switch (lang.current_lang) {
        case "fr":
          return "fr-CA";
        case "en":
          return "en-CA";
        case "es":
          return "es-Es";
        default:
          return "en-CA";
      }
    }
    return (
      <div id="previously-completed" className="page">
        <section>
          <Header
            assesmentTest={assesmentTest}
            remote={assesment.currently_remote}
            assesmentTests={assesment.assesment_tests}
          />
          <h3>
            <FormattedMessage id="user_test.title.previously-completed" />
          </h3>
          <p>
            <FormattedMessage id="user_test.you-already-completed" />
            {helpers
              .strToDate(previousAssesmentTest.completed_at)
              .toLocaleDateString(getLocaleDateString(), {
                weekday: "long",
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            .
          </p>
          {isTestChronoCompletedLessThan30DaysAgo() && (
            <div>
              <p>
                <FormattedMessage id="user_test.answer-automatically-copied-from-previous-test" />
              </p>
              <button
                onClick={props.refreshHandler}
                className="btn btn-primary"
              >
                <FormattedMessage id="app.global.button.continue" />
              </button>
            </div>
          )}
          {!isTestChronoCompletedLessThan30DaysAgo() && (
            <div>
              <p>
                <FormattedMessage id="user_test.answer-again" values={
                  { 
                    AnswerAgain: <strong>{props.intl.formatMessage({id: 'user_test.answer-again_answer_again'})}</strong>,
                    UsePrevious: <strong>{props.intl.formatMessage({id: 'user_test.answer-again_use_previous'})}</strong>
                  }
                  } />
              </p>
              <div className="button-bar">
                <button
                  onClick={props.answerAgainHandler}
                  className="btn btn-primary"
                >
                  <FormattedMessage id="previously-completed.label.answer-again" />
                </button>
                <button
                  onClick={props.usePreviousHandler}
                  className="btn btn-default"
                >
                  <FormattedMessage id="previously-completed.label.use-previous-answers" />
                </button>
              </div>
            </div>
          )}
        </section>
      </div>
    );
  }

export default injectIntl(PreviouslyCompletedTest);
