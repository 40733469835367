import * as types from "./actionTypes";

export const loginRequested = (token) => ({
  type: types.LOGIN_REQUEST,
  token,
});
export const loginSuccessful = (response) => ({
  type: types.LOGIN_SUCCESSFUL,
  response,
});
export const loginFailure = (token, error) => ({
  type: types.LOGIN_FAILURE,
  token,
  error,
});

export const logoutRequested = () => ({
  type: types.LOGOUT_REQUEST,
});
export const logoutSuccessful = (response) => ({
  type: types.LOGOUT_SUCCESSFUL,
  response,
});
export const logoutFailure = (error) => ({
  type: types.LOGOUT_FAILURE,
  error,
});
