import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

class HowToDisableTranslator extends Component {
  render() {
    return (
      <div id="error" className="page">
        <section>
          <h1>
            <FormattedMessage id="error.translator.title" />
          </h1>
          <p>
            <FormattedMessage id="error.translator.text" values={{HomeLink:<a href="../">{this.props.intl.formatMessage({id:"error.translator.text_link"})}</a>}} />
          </p>
          <ul className="bullet">
            <li>
              <strong>Google Chrome</strong><FormattedMessage id="app.global.semi-colon" defaultMessage=":" /> 
              <a href="https://support.google.com/chrome/answer/173424"><FormattedMessage id="error.translator.li.chrome_page_title" /></a>
            </li>
            <li>
            <strong>Microsoft Edge</strong><FormattedMessage id="app.global.semi-colon" defaultMessage=":" /> 
              <a href={this.props.intl.formatMessage({id:"error.translator.li.edge_url"})}><FormattedMessage id="error.translator.li.edge_page_title" /></a>
            </li>
          </ul>
        </section>
      </div>
    );
  }
}

export default injectIntl(HowToDisableTranslator);
