import React from "react";
import { useIntl } from 'react-intl';

const OpenAnswer = (props)=>{
  const { current, answer, onChange } = props;
  const intl          = useIntl();
  

  if(!current) return <></>;

  return (
    <div id="open-answer">
      <textarea
        id="open-answer-input"
        placeholder={intl.formatMessage({ id: 'user-test.textarea.placeholder' })}
        onChange={onChange}
        defaultValue={answer}
        maxLength="2000"
      />
    </div> 
  );
     
}

export default OpenAnswer;