import { useEffect, useState  } from 'react';
import MainCard from './MainCard';
import DropZones from './DropZones';
import Consigne from './Consigne';
import { useSelector, useDispatch } from "react-redux";
import * as assesmentTestQuestionActions from "redux/actions/assesmentTestQuestion/index";
import Footer from '../Footer';
import ProgressBar from '../../components/ProgressBar';

const DragAndDrop = ({previousHandler, nextHandler}) => {

  const dispatch = useDispatch();
  const assesmentTest = useSelector((state) => state.assesmentTest);
  const { updateAssesmentTestQuestion } = assesmentTestQuestionActions;

  const [maxBusted, setMaxBusted]     = useState(false);
  const [nbAnswered, setNbAnswered]   = useState(0);
  const [allAnswered, setAllAnswered] = useState(false);

  useEffect(() => {
    const questions = assesmentTest.assesment_test_questions;  
    const basketdetails = assesmentTest.test.test_baskets[0].basket_details;
    let maxBusted = basketdetails.some(basket => {
      return questions.filter(item => item.answers.includes(basket.answer_definition_choice.id)).length > basket.item_count;
    });
    
    setMaxBusted(maxBusted);
    const a = questions.filter(item => item.answers.length > 0).length;
    setNbAnswered(a);
    setAllAnswered(a === questions.length);

    return () => {
      setMaxBusted(false);
      setNbAnswered(0);
      setAllAnswered(false);
    }
    }, [assesmentTest.assesment_test_questions]);

  const save = (id, payload) => {
    dispatch(updateAssesmentTestQuestion(id, payload));
  };

  return (
    <>
      <ProgressBar
        total={assesmentTest.assesment_test_questions.length}
        currentIndex={ nbAnswered }
      />
      <div id="premier-tri">
        <Consigne />
        <MainCard save={save} />    
        <DropZones
          baskets={assesmentTest.test.test_baskets[0].basket_details}
          items={assesmentTest.assesment_test_questions}
          save={save}
        />
      </div>
      <Footer previousHandler={previousHandler} nextHandler={nextHandler} nextDisabled={!allAnswered || maxBusted} />
    </>
  );
}

export default DragAndDrop;