import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as assesmentTestActions from "redux/actions/assesmentTest/index";
import { formatRemainingTime } from "./helper";
import TimesUpModal from "../TimesUpModal.js";

const INTERVAL_UPDATE_LOCAL = 1 * 1000; // n seconds * 1000;
const INTERVAL_UPDATE_DB    = 5 * 1000; // n seconds * 1000;

const {
  updateAssesmentTestElapsedTimeLocally,
  updateAssesmentTest,
  markAssesmentTestAsCompleted
} = assesmentTestActions;

const Timer = ({ saveQuestion }) => {
  
  const history   = useHistory();
  const dispatch  = useDispatch();
  const updateLocalIntervalId = useRef(null);
  const updateDBIntervalId    = useRef(null);
  const assesment                   = useSelector((state) => state.assesment);
  const assesmentTest               = useSelector((state) => state.assesmentTest);
  const assesmentTestQuestionStatus = useSelector((state) => state.assesmentTestQuestionStatus);
  const {isUpdating, isImgLoading, isFetching}  = assesmentTestQuestionStatus;
  
  const total       = useRef(null); // Total time based on test duration
  const elapsedToDB = useRef(null); // Elapsed time to save to DB
  const [elapsed, setElapsed]     = useState(null); // Elapsed time
  const [countDown, setCountDown] = useState(null); // Remaining time to display
  const [timeIsUp, setTimeIsUp]   = useState(false); // boolean

  /**
   * Reset state when component unmounts
   */
  useEffect(() => {
    return () => {
      if(elapsed && total.current) saveProgressToDB();
      clearInterval(updateLocalIntervalId.current);
      clearInterval(updateDBIntervalId.current);
      setElapsed(null);
      total.current = null;
      elapsedToDB.current = null;
    }
  }, []);
  
  /**
   * Initialize elapsed time and total time.
   * Set intervals to update elapsed time and save to DB.
   */
  useEffect(() => {
    if(!assesmentTest.test.duration_max) return;

    // Set elapsed time to 0 or to the value stored in the DB / local storage
    setElapsed(assesmentTest.elapsed_time ? assesmentTest.elapsed_time : 0);

    // Total time could be adapted or not
    const is_adapted = assesment.candidate_adapted_measures_factor && assesmentTest.adapted_measures_allowed;
    const adapted_extra_time = is_adapted ? assesmentTest.test.duration_max * assesment.candidate_adapted_measures_factor / 100 : 0;
    total.current = assesmentTest.test.duration_max + adapted_extra_time;

    // Set intervals to update elapsed time and save to DB
    updateLocalIntervalId.current = setInterval(tick, INTERVAL_UPDATE_LOCAL);
    updateDBIntervalId.current    = setInterval(saveProgressToDB, INTERVAL_UPDATE_DB);

  }, [assesmentTest.test.duration_max]);
  

  /**
   * Update elapsed and countdown.
   */
  useEffect(() => {
    if(elapsed === null || total === null) return;

    dispatch(updateAssesmentTestElapsedTimeLocally(assesmentTest.id, elapsed));
    elapsedToDB.current = elapsed;

    if (elapsed >= total.current){
      setCountDown(formatRemainingTime(0));
      setTimeIsUp(true);
    }else {
      setCountDown(formatRemainingTime(total.current - elapsed));
    }

  }, [elapsed]);


  /**
   * Handle timer expiration.
   * Save elapsed time to DB and mark test as completed.
   * Remove elapsed time from local storage.
   * Redirection to assesment-test page is handled by TimesUpModal.
   */
  useEffect(() => {
    if (!timeIsUp) return;
    saveQuestion();
    clearInterval(updateLocalIntervalId.current);
    clearInterval(updateDBIntervalId.current);
    dispatch(markAssesmentTestAsCompleted(assesmentTest.id, { timerIsOver: true }))
    .then(() => {
      localStorage.removeItem("elapsed");
    });
    
  }, [timeIsUp]);


  /**
   * Increment elapsed time unless image is loading or question is updating.
   */
  const tick = () => {
    if(isUpdating === false && isImgLoading === false && isFetching === false){
      setElapsed(prevElapsed => prevElapsed + (INTERVAL_UPDATE_LOCAL / 1000));
    }
  }

  /**
   * Dispatch redux action to save elapsed time to the DB.
   */
  const saveProgressToDB = () => {
    dispatch(updateAssesmentTest(assesmentTest.id, { elapsed_time: elapsedToDB.current }));
  }

  if (!window.localStorage) {
    history.push("/browser-outdated");
    return <></>;
  }

  if(!assesmentTest.test.duration_max) return <></>;

  return (
    <div id="timer-wrapper">
      <div id="timer-progress" style={{ width: `${Math.floor(100 - (elapsed / total.current) * 100)}%` }} />
      <div id="count-down">{countDown}</div>
      <TimesUpModal
        show={timeIsUp}
        close={()=>{history.push("/assesment-test")}} 
        audienceId={assesment.evaluation_session.audience_type_id}
      />
    </div>
  );
}

export default Timer;
