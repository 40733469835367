import { FormattedMessage } from "react-intl";
import { useTranslation } from "hooks/useTranslation";

function Example(props) {
  const { test } = props;
  const image_example = useTranslation("image_example", test);

  if (!test || !image_example) {
    return <></>;
  }
  return (
    <div>
      <h3>
        <FormattedMessage id="user_test.title.example" />
      </h3>
      <div className="question-example-wrapper">
        <img
          src={image_example}
          alt="Question example"
        />
      </div>
    </div>
  );
}

export default Example;
