import React, { useState, useEffect } from "react";
import * as authActions from "../../redux/actions/auth/index";
import * as langActions from "../../redux/actions/lang/index";
import LoadingScreen from "../helpers/loadingScreen";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";

const CodePermLogin = (props) => {
  
  
  const intl = useIntl();
  
  const auth = useSelector((state) => state.auth);
  const error = useSelector((state) => state.authStatus.error);
  const isFetching = useSelector((state) => state.authStatus.isFetching);
  
  const dispatch = useDispatch();

  const [code_perm, setCodePerm] = useState(undefined);
  const [invalid_format, setInvalidFormat] = useState(false);

  useEffect(() => {
    let prefLang = 'fr';
    if (props.location.pathname === "/permanent-code") {
      prefLang = 'en';
    }else if(props.location.pathname === "/codigo-permanente"){
      prefLang = 'es';
    }
    dispatch(langActions.setCandidatePrefLang(prefLang));
  }, []);


  const submitBtnHandler = (e) => {
    e.preventDefault();

    if (document.getElementById("codePerm").validity.valid) {
      dispatch(authActions.doCodePermLogin(code_perm));
    } else {
      setInvalidFormat(true);
    }
  };

  const codePermChangeHandler = (e) => {
    setCodePerm(e.currentTarget.value);
    setInvalidFormat(false);
  };

  if (auth && auth.isLoggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <div id='code-perm-login'>
      <div className='wrapper'>
        <h1 className='title'>
          <FormattedMessage id='code-perm-login.title.login' />
        </h1>
        <p>
          <FormattedMessage id='code-perm-login.p1' />
        </p>
        <p>
          <FormattedMessage id='code-perm-login.p2' />
        </p>

        <form>
          <label htmlFor='codePerm'>
            <FormattedMessage id='code-perm-login.label.code-perm' />
          </label>
          <input
            type='text'
            name='codePerm'
            id='codePerm'
            minLength='12'
            maxLength='12'
            required
            pattern='[A-Za-z]{4}[0-9]{8}'
            defaultValue={code_perm}
            onChange={codePermChangeHandler}
            placeholder='AAAA00000000'
            autoComplete='off'
          />
          {invalid_format && (
            <p className='text-error'>
              <FormattedMessage id='code-perm-login.error.invalid-format' />
            </p>
          )}
          {error && error.response && error.response.status === 404 && (
            <p className='text-error'>
              <FormattedMessage id='code-perm-login.error.code-perm-not-found' />
            </p>
          )}
          {error && error.response && error.response.status === 422 && (
            <p className='text-error'>
              <FormattedMessage id='code-perm-login.text.no-time-slot' />
            </p>
          )}
          <button className='btn btn-lg btn-primary' onClick={submitBtnHandler} disabled={!code_perm}>
            <FormattedMessage id='app.global.button.submit' />
          </button>
        </form>
        <p className='text-small text-muted'>
          <FormattedMessage id='code-perm-login.p3' />
        </p>
        <p className='text-small text-muted'>
          <FormattedMessage id='code-perm-login.p4' />
        </p>
        <p className='text-small text-muted'>
          <FormattedMessage id='code-perm-login.p5' />
        </p>
        <p className='text-small text-muted'>
          <a href={intl.formatMessage({ id: "code-perm-login.p6_url" })}>
            <img 
              src={intl.formatMessage({ id: "code-perm-login.p6_img_src" })}
              alt={intl.formatMessage({ id: "code-perm-login.p6_img_alt" })}
              style={{width: "427px"}}
            />
          </a>
        </p>
      </div>
      {isFetching && <LoadingScreen />}
    </div>
  );
}
export default CodePermLogin;
