import React, { Component } from 'react';

class Footer extends Component {
  render(){
    return(
      <footer>
        <div className="footer-content">
        <a href="https://www.e-potentiel.com" className="subtle" target="blank">© {(new Date()).getFullYear()}</a> - v{ process.env.REACT_APP_VERSION }
        </div>
      </footer>
    )
  }
}

export default Footer
