import React from 'react';
import { useTranslation } from 'hooks/useTranslation';
import { helpers } from '../../../../helpers/helpers';

const Info = ({item}) => {
  const info = useTranslation("info", item?.question);

  // Use this component to render html encoded accent
  const renderHTML = (rawHTML) => React.createElement("p", { dangerouslySetInnerHTML: { __html: rawHTML } });

  return (
    <div>
      {info && renderHTML(helpers.stripHtmlTags(info))}
    </div>
  )
}

export default Info;