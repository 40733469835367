import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as translationActions from "../redux/actions/translation/index";

export const useTranslation = (key, dict) => {

  const langStore = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  const [translation, setTranslation] = useState(dict?.[key]?.[langStore.current_lang] ?? "");

  if(typeof dict === 'undefined' || dict === null){
    console.error("No dictionary found", dict);
    //return null;  
  }

  if(dict[key] === undefined){
    console.error("Key not found", dict, key);
    //return null;  
  }

  // Translate when ever the language changes or the dictionary changes
  useEffect(() => {
    const current_lang = langStore.current_lang;
    if(dict[key][current_lang]) {
      if(translation !== dict[key][current_lang]){
        setTranslation(dict[key][current_lang]);
      }
    } else {
      dispatchMissingTranslation(key, dict, langStore, dispatch);
      setTranslation("");
    }
  }, [langStore.current_lang, dict]);
  

  return translation;
};

/**
 * Retrieves the fallback language for a given key and dictionary.
 *
 * @param {string} key - The key to search for in the dictionary.
 * @param {object} dict - The dictionary containing the translations.
 * @param {object} langStore - The language store object.
 * @returns {string|null} - The fallback language or null if not found.
 */
const getFallbackLang = (key, dict, langStore) => {
  let fallbackLang = null;
  const fallbackPrecedences = [
    "forced_lang",
    "candidate_pref_lang",
    "candidate_profile_lang",
    "default_app_lang",
  ];

  for (let i = 0; i < fallbackPrecedences.length; i++) {
    let lang = langStore[fallbackPrecedences[i]];
    if (lang) {
      if (dict[key][lang]) {
        fallbackLang = lang;
        break;
      }
    }
  }

  return fallbackLang;
};


/**
 * Dispatches a missing translation action.
 *
 * @param {string} key - The key of the translation.
 * @param {object} dict - The dictionary containing the translations.
 * @param {object} langStore - The language store object.
 * @param {function} dispatch - The dispatch function from react-redux.
 * @returns {void}
 */
const dispatchMissingTranslation = (key, dict, langStore, dispatch) => {
  let fallbackLang = getFallbackLang(key, dict, langStore);
  if (!fallbackLang) return;
  dispatch(
    translationActions.setTranslationMissing({
      is_missing: true,
      lang_used: fallbackLang,
    })
  );
}
