import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import DragAndDrop from './dragAndDrop/DragAndDrop';
import Picker from './picker/Picker';
import { set } from 'lodash';

const MODE_DRAG_AND_DROP  = 'PA1';
const MODE_PICKER         = 'PA2';

const CardSorting = () => {

  const assesmentTest = useSelector((state) => state.assesmentTest);
  const [stepIndex, setStepIndex]     = useState(null);
  const [currentStep, setCurrentStep] = useState(null);
  const next      = () => { setStepIndex(i => i + 1);};
  const previous  = () => { setStepIndex(i => i - 1);};
  const history = useHistory();

  useEffect(() => {
    setStepIndex(0);
    setCurrentStep(assesmentTest.test.test_baskets[0]);
  }, []);

  useEffect(() => {
    if(stepIndex < 0){
      history.push("/assesment-test");
    }else if (stepIndex >= 0 && stepIndex < assesmentTest.test.test_baskets.length){
      setCurrentStep(assesmentTest.test.test_baskets[stepIndex]);
    }else {
      history.push("completed");
    }

  }, [stepIndex]);

  if(!currentStep) return null;
  return (
    <div id="card-sorting">
      {currentStep.answer_definition.answer_mode_id === MODE_DRAG_AND_DROP &&
        <DragAndDrop previousHandler={previous} nextHandler={next} />
      }
      {currentStep.answer_definition.answer_mode_id === MODE_PICKER &&
        <Picker previousHandler={previous} nextHandler={next} />
      }
    </div>
  );
}

export default CardSorting;