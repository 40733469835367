import * as actionTypes from "../actions/candidate/actionTypes";
import { LOGOUT_REQUEST } from "../actions/auth/actionTypes";
import { combineReducers } from "redux";

const initialState = {
  lang: process.env.REACT_APP_DEFAULT_LANG,
};

export const candidate = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CANDIDATE_SUCCESSFUL:
      return {
        ...state,
        ...action.response
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        id: undefined,
        first_name: undefined,
        last_name: undefined,
        dob: undefined,
        sex: undefined,
      };
    default:
      return state;
  }
};
const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CANDIDATE_REQUEST:
      return true;
    case actionTypes.FETCH_CANDIDATE_SUCCESSFUL:
    case actionTypes.FETCH_CANDIDATE_FAILURE:
      return false;
    default:
      return state;
  }
};
const isUpdating = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_CANDIDATE_REQUEST:
      return true;
    case actionTypes.UPDATE_CANDIDATE_SUCCESSFUL:
    case actionTypes.UPDATE_CANDIDATE_FAILURE:
      return false;
    default:
      return state;
  }
};
const error = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CANDIDATE_SUCCESSFUL:
    case actionTypes.FETCH_CANDIDATE_REQUEST:
    case actionTypes.UPDATE_CANDIDATE_SUCCESSFUL:
    case actionTypes.UPDATE_CANDIDATE_REQUEST:
      return null;
    case actionTypes.FETCH_CANDIDATE_FAILURE:
    case actionTypes.UPDATE_CANDIDATE_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const candidateStatus = combineReducers({ isFetching, isUpdating, error });
export default candidateStatus;
export const getError = (state) => state.error;
export const getIsFetching = (state) => state.isFetching;
export const getIsUpdating = (state) => state.isUpdating;
