import { combineReducers } from "redux";
import candidateStatus, { candidate } from "./candidate";
import {lang} from "./lang";
import {translation} from "./translation";
import authStatus, { auth } from "./auth";
import assesmentStatus, { assesment } from "./assesment";
import assesmentTestStatus, { assesmentTest } from "./assesmentTest";
import assesmentTestQuestionStatus, { assesmentTestQuestion } from "./assesmentTestQuestion";

export default combineReducers({
    candidate, candidateStatus,
    auth, authStatus,
    lang, translation,
    assesment, assesmentStatus,
    assesmentTest, assesmentTestStatus,
    assesmentTestQuestion, assesmentTestQuestionStatus,
});

