import * as actions from "./actionTypes";


const fetchAssesmentRequested = () => ({
    type: actions.FETCH_ASSESMENT_REQUEST,
})
const fetchAssesmentSuccessful = (response) => ({
    type: actions.FETCH_ASSESMENT_SUCCESSFUL,
    response
})
const fetchAssesmentFailure = (error) => ({
    type: actions.FETCH_ASSESMENT_FAILURE,
    error
})
export const fetchAssesment = (id) => (dispatch, getState, api) => {
    dispatch(fetchAssesmentRequested());
    return new Promise(async (resolve, reject) => {
        await api.assesment.get(id).then(
            response => {
                dispatch(fetchAssesmentSuccessful(response.data));
                resolve(response.data);
            },
            error => {
                dispatch(fetchAssesmentFailure(error));
                reject(error);
            }
        );
    });
}

const updateAssesmentRequested = () => ({
    type: actions.UPDATE_ASSESMENT_REQUEST,
})
const updateAssesmentSuccessful = (response) => ({
    type: actions.UPDATE_ASSESMENT_SUCCESSFUL,
    response
})
const updateAssesmentFailure = (error) => ({
    type: actions.UPDATE_ASSESMENT_FAILURE,
    error
})
export const updateAssesment = (id, payload) => (dispatch, getState, api) => {
    dispatch(updateAssesmentRequested());
    return new Promise(async (resolve, reject) => {
        await api.assesment.put(id, payload).then(
            response => {
                dispatch(updateAssesmentSuccessful(response.data));
                resolve(response.data);
            },
            error => {
                dispatch(updateAssesmentFailure(error));
                reject(error);
            }
        );
    });
}