import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { helpers } from '../../../helpers/helpers';
import Audio from './Audio';
import Image from './Image';
import { useTranslation } from 'hooks/useTranslation';

function Question(props){
  const { current } = props; // current refers to the current assesment_test_question
  const [isMathJaxReady, setIsMathJaxReady] = useState(__MathJax_State__.isReady);
  const headerQuestionClassNames = classNames (
    'header-question',
    {
      'narrow': (current.question.id_image && current.question.id_image !== 0) || (current.question.info_fr && current.question.info_fr !== ""),
    }
    );
    
  const text = useTranslation("text", current.question);
  const info = useTranslation("info", current.question);
  
  // Refresh MathJax each time a question changes
  useEffect(()=>{
    // Avoid running this script if the MathJax library hasn't loaded yet.
    if (!isMathJaxReady) {
      // But trigger a re-render of this component once it is loaded.
      __MathJax_State__.promise.then(() => setIsMathJaxReady(true));
      return;
    }
    MathJax.typeset();
  },[info, text]);

  return (
    <div id="question" key={current.id}>
      <h2 className={ headerQuestionClassNames }> {
        text &&
        helpers.replaceSapceBeforeColon(helpers.capitalize(text))
        }
      </h2>
      {
        info &&
          <div 
            id="question-text"
            className="question-text"
            dangerouslySetInnerHTML={{__html:info}}
          />
      }
      <Image current={current} />
      <Audio current={current} />
    </div>
    );
}

export default Question;