import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import * as assesmentTestQuestionActions from "redux/actions/assesmentTestQuestion/index";
import { useTranslation } from 'hooks/useTranslation';
import MediumCard from './MediumCard'
import ProgressBar from '../../components/ProgressBar';
import Footer from '../Footer';

const Picker = ({previousHandler, nextHandler}) => {

  const dispatch = useDispatch();
  const assesmentTest = useSelector(state => state.assesmentTest);
  const { updateAssesmentTestQuestion } = assesmentTestQuestionActions;  
  const [index, setIndex] = useState(0);
  const [current, setCurrent] = useState(assesmentTest.test.test_baskets[1].basket_details[index]);
  const getItems = () => {
    return assesmentTest.assesment_test_questions.filter(item => item.answers.includes(current.items_source_answer_definition_choice_id))
  }
  const [items, setItems] = useState(getItems());
  const question = useTranslation("instructions", current);

  useEffect(() => {
    if(index < 0){
      previousHandler();
    }else if (index >= 0 && index < assesmentTest.test.test_baskets[1].basket_details.length){
      setCurrent(assesmentTest.test.test_baskets[1].basket_details[index]);
    }else {
      nextHandler();
    }
  }, [index]);

  useEffect(() => {
    setItems(getItems());
  }, [current]);

  useEffect(() => {
    setItems(getItems());
  }, [assesmentTest.assesment_test_questions]);

  const previous = () => { setIndex(index - 1); }
  const next = () => { setIndex(index + 1); }
  const save = (id, payload) => { dispatch(updateAssesmentTestQuestion(id, payload)); }

  return(
    <>
    <ProgressBar
      total={ current.item_count }
      currentIndex={ assesmentTest.assesment_test_questions.filter(item => item.answers.includes(current.answer_definition_choice.id)).length }
    />
    <div id="top-bottom-picker">
      <p>{ question }</p>
      <div id="medium-cards-wrapper">
        {
          items.map(
          (item, index)=>{
          return <MediumCard
                    key={index}
                    question={item.question}
                    checked={item.answers.includes(current.answer_definition_choice.id)}
                    onClick={() => {
                      const newAnswers = item.answers.includes(current.answer_definition_choice.id)
                        ? item.answers.filter(id => id !== current.answer_definition_choice.id)
                        : [...item.answers, current.answer_definition_choice.id];
                      save(item.id, {answers: newAnswers});
                    }}
                    disabled={assesmentTest.assesment_test_questions.filter(item => item.answers.includes(current.answer_definition_choice.id)).length >= current.item_count && !item.answers.includes(current.answer_definition_choice.id)}
                  />
            }
        )}
      </div>
    </div>
    <Footer previousHandler={previous} nextHandler={next} nextDisabled={assesmentTest.assesment_test_questions.filter(item => item.answers.includes(current.answer_definition_choice.id)).length < current.item_count} />
    </>
  );
  }
  export default Picker;