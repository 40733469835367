import React, { Component } from 'react';
import { FormattedMessage }  from 'react-intl';

  class NoLocalStorage extends Component {
    render() {
      return (
        <div id="error" className="page">
          <section>
            <h1><FormattedMessage id="error.noLocalStorage.title" defaultMessage="Problème au niveau du navigateur web" /></h1>
            <p><FormattedMessage id="error.noLocalStorage.text" defaultMessage="Impossible de poursuivre les tests." /></p>
          </section>
        </div>
      )
    }
  }

  export {NoLocalStorage};