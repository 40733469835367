import * as actions from "./actionTypes";


const fetchAssementTestQuestionRequested = () => ({
    type: actions.FETCH_ASSESMENT_TEST_QUESTION_REQUEST,
})
const fetchAssementTestQuestionSuccessful = (response) => ({
    type: actions.FETCH_ASSESMENT_TEST_QUESTION_SUCCESSFUL,
    response
})
const fetchAssementTestQuestionFailure = (error) => ({
    type: actions.FETCH_ASSESMENT_TEST_QUESTION_FAILURE,
    error
})
export const fetchAssementTestQuestion = () => (dispatch, getState, api) => {
    dispatch(fetchAssementTestQuestionRequested());
    return new Promise(async (resolve, reject) => {
        await api.assesmentTestQuestion.get().then(
            response => {
                dispatch(fetchAssementTestQuestionSuccessful(response.data));
                resolve(response.data);
            },
            error => {
                dispatch(fetchAssementTestQuestionFailure(error));
                reject(error);
            }
        );
    });
}

const updateAssesmentTestQuestionRequested = () => ({
    type: actions.UPDATE_ASSESMENT_TEST_QUESTION_REQUEST,
})
const updateAssesmentTestQuestionSuccessful = (response) => ({
    type: actions.UPDATE_ASSESMENT_TEST_QUESTION_SUCCESSFUL,
    response
})
const updateAssesmentTestQuestionFailure = (error) => ({
    type: actions.UPDATE_ASSESMENT_TEST_QUESTION_FAILURE,
    error
})
export const updateAssesmentTestQuestion = (id, payload) => (dispatch, getState, api) => {
    dispatch(updateAssesmentTestQuestionRequested());
    return new Promise(async (resolve, reject) => {
        await api.assesmentTestQuestion.put(id, payload).then(
            response => {
                dispatch(updateAssesmentTestQuestionSuccessful(response.data));
                resolve(response.data);
            },
            error => {
                dispatch(updateAssesmentTestQuestionFailure(error));
                reject(error);
            }
        );
    });
}

export const usePrevAnswersAssementTestQuestion = (id, id_test, payload) => (dispatch, getState, api) => {
    dispatch(updateAssesmentTestQuestionRequested());
    return new Promise(async (resolve, reject) => {
        await api.assesmentTestQuestion.usePrevAnswers(id, id_test, payload).then(
            response => {
                dispatch(updateAssesmentTestQuestionSuccessful(response.data));
                resolve(response.data);
            },
            error => {
                dispatch(updateAssesmentTestQuestionFailure(error));
                reject(error);
            }
        );
    });
}


export const loadImgAssementTestQuestionRequested = (id) =>(dispatch)=> {
    dispatch({type: actions.LOAD_IMG_ASSESMENT_TEST_QUESTION_REQUEST,id})
}
export const loadImgAssementTestQuestionSuccessful = (id) =>(dispatch)=> {
    dispatch({type: actions.LOAD_IMG_ASSESMENT_TEST_QUESTION_SUCCESSFUL,id})
}
export const loadImgAssementTestQuestionFailure = (id, error) =>(dispatch)=> {
    dispatch({type: actions.LOAD_IMG_ASSESMENT_TEST_QUESTION_FAILURE,id, error})
}