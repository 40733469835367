import React, { useState } from "react";
import { helpers } from "../../../../helpers/helpers";
import { useTranslation } from "hooks/useTranslation";

const MiniCard = (props) => {
  const [showDescription, setShowDescription] = useState(false);

  const onDragStart = (e) => {
    e.dataTransfer.setData("text/plain", props.value);
    e.dataTransfer.effectAllowed = "move";
  };

  // Use this component to render html encoded accent
  const renderHTML = (rawHTML) =>
    React.createElement("span", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  const label = useTranslation("text", props.item.question);
  const description = useTranslation("info", props.item.question);

  return (
    <li
      id={props.id}
      draggable="true"
      onDragStart={onDragStart}
      data-value={props.value}
      className="mini-card"
    >
      <div>
        <button
          className="remove-btn"
          onClick={() => {
            props.removeCard(props.value);
          }}
        >
          <i className="icon-cancel"></i>
        </button>
        {label}
      </div>

      <span
        className="show-description-btn"
        onClick={(e) => {
          setShowDescription(!showDescription);
        }}
        onMouseOut={(e) => {
          setShowDescription(false);
        }}
      >
        <i className="icon-info-circled"></i>
      </span>

      {showDescription && (
        <div className="description-tooltip">
          {renderHTML(helpers.stripHtmlTags(description))}
        </div>
      )}
    </li>
  );
};

export default MiniCard;
