import React from "react";
import * as AUDIENCES from "constants/audiences";
import { FormattedMessage, useIntl } from "react-intl";

const TimesUpModal = ({show, close, audienceId}) => {

  if(show === false){
    return <></>
  }

  
  const intl = useIntl();
  const audience = audienceId === AUDIENCES.ACADEMIC_PRIMARY ? "academic10" : "professional";
  const continueLabel = intl.formatMessage({id: `timer.times-up.${audience}.button.continue`});

  return (
    <div className="modal">
      <div className="modal-lg modal-wrapper">
        <div className="modal-head">
          <h2>
            <FormattedMessage id={`timer.times-up.${audience}.title`} />
          </h2>
        </div>
        <div className="modal-body">
          <p>
            <FormattedMessage
              id={`timer.times-up.${audience}.p1`}
              values={{ContinueLabel: <strong>{continueLabel}</strong>}}
            />
          </p>

          {audience === "academic10" && (
            <p>
              <FormattedMessage
                id={"timer.times-up.academic10.p2"}
                values={{ContinueLabel: <strong>{continueLabel}</strong>}}
              />
            </p>
          )}
        </div>
        <div className="modal-footer">
          <button onClick={close} className="btn btn-primary">
            <FormattedMessage
              id={`timer.times-up.${audience}.button.continue`}
            />
          </button>
        </div>
      </div>
    </div>
  );
}

export default TimesUpModal;
