import { FormattedMessage } from "react-intl";
import Timer from "../../test/questionnaire/components/timer/Timer";
import ProgressBar from "../../test/questionnaire/components/ProgressBar";
import MultipleChoices from "../../test/questionnaire/multiplechoices/MultipleChoices";
import { useHistory } from "react-router";
import { useState } from "react";
import assesmentTest from "./assesmentTest.json";
import assesment from "./assesment.json";

const DemoProfessionalTest = (props) => {
  const history = useHistory();

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState();
  const [currentQuestionOrder, setCurrentQuestionOrder] = useState();
  const [progressTotal, setProgressTotal] = useState();
  
  const onNoLocalStorage = ()=>{
    history.push("/browser-outdated");
  }
  const timesUp = ()=>{
    
  }
  const timesUpModalClosed = ()=>{
    
  }
      
  const testCompletedHandler = ()=>{
    //reset all answers
    assesmentTest.utilisateur_session_questions.map((question=>{
      question.id_reponse = null;
      question.reponse_explication = null;
      question.reponse_ouverte = null;
      question.id_reponses_multiples= null,
      question.dt_reponse= null
    }))

    props.next();
  } 

  const previousHandler = ()=>{}

  const updateProgress = (total, current) => {
    setCurrentQuestionIndex(current);
    setCurrentQuestionOrder(current > -1 ? current + 1 : undefined);
    setProgressTotal(total);
  };
  const saveHandler = (id, payload, callback)=>{
    let question = assesmentTest.utilisateur_session_questions.filter(question=>Number(question.id) === Number(id));
    question = {...question, ...payload};

    if(callback){
      callback();
    }
  }

  return (
      <div id='test'>
          <div id="timer-wrapper">
            <div id="timer-progress" style={{ width: "0%" }} />
            <div id="count-down">00:00:00</div>
          </div>

          <ProgressBar
            id='progress-bar'
            total={progressTotal}
            currentIndex={currentQuestionIndex}
            currentQuestionOrder={currentQuestionOrder}
            className='progress-bar'
          />

        <MultipleChoices
          questions={assesmentTest.utilisateur_session_questions}
          test={assesmentTest.test}
          assesment={assesment}
          updateProgress={updateProgress}
          saveHandler={saveHandler}
          previousHandler={previousHandler}
          testCompletedHandler={testCompletedHandler}
          isTimeUp={false}
          lang={props.pref_lang}
        />
      </div>
    );
};

export default DemoProfessionalTest;