import React from "react";
import MiniCard from "./MiniCard";
import { useTranslation } from "hooks/useTranslation";

const DropZone = ({dropzone, value, items, max, save}) => {
  const label = useTranslation("label", dropzone.answer_definition_choice);

  return (
    <div
      id={`drop-zone-${value}`}
      className="drop-zone"
      data-value={value}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        save(e.dataTransfer.getData("text"), { answers: [value],});
      }}
    >
      <div className="drop-zone-title">
        <h5 className="unselectable">{label}</h5>
        <div
          id={`counter-${value}`}
          className={
            "counter" + (items.length > max ? " full" : "")
          }
        >
          <span className="unselectable">{items.length}</span>
        </div>
      </div>
      <ul id={`drop-zone-${value}-list`}>
        {items.map((item) => (
          <MiniCard
            key={`mini-card-${item.id}`}
            id={`mini-card-${item.id}`}
            className="drop-zone"
            value={item.id}
            item={item}
            removeCard={() => {
              save(item.id, { answers: [] });
            }}
          />
        ))}
      </ul>
    </div>
  );
};

export default DropZone;
