import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

// constants ----------------------------------------------------
const MONTHS = [
  "global.date.month.january",
  "global.date.month.february",
  "global.date.month.march",
  "global.date.month.april",
  "global.date.month.may",
  "global.date.month.june",
  "global.date.month.july",
  "global.date.month.august",
  "global.date.month.september",
  "global.date.month.october",
  "global.date.month.november",
  "global.date.month.december",
];

let days = [];
for (let i = 1; i <= 31; i++) {
  days.push(
    <option key={i} value={i}>
      {i}
    </option>
  );
}
days.splice(
  0,
  0,
  <option key="-1" value="">
    --
  </option>
);

let months = MONTHS.map((item, index) => {
  return (
    <FormattedMessage id={item} key={index}>
      {(txt) => (
        <option key={item} value={index + 1}>
          {txt}
        </option>
      )}
    </FormattedMessage>
  );
});
months.splice(
  0,
  0,
  <option key="-1" value="">
    --
  </option>
);

let years = [];
let current_year = new Date().getFullYear();
for (let i = current_year; i >= current_year - 80; i--) {
  years.push(
    <option key={i} value={i}>
      {i}
    </option>
  );
}
years.splice(
  0,
  0,
  <option key="-1" value="">
    --
  </option>
);

// --------------------------------------------------------------

class DatePickerSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      day: undefined,
      month: undefined,
      year: undefined,
      date: undefined,
    };

    this.onChange = this.onChange.bind(this);
  }

  setDate(date) {
    let year, month, day;
    if (date) {
      [year, month, day] = this.props.date.split("-");
      date = new Date(year, month - 1, day);
    }
    this.setState(
      {
        day: date ? date.getDate() : undefined,
        month: date ? date.getMonth() + 1 : undefined,
        year: date ? date.getFullYear() : undefined,
        date: date ? [year, month, day].join("-") : undefined,
      },
      () => {
        this.props.onChange(this.state.year, this.state.month, this.state.day);
      }
    );
  }

  componentDidMount() {
    this.setDate(this.props.date);
  }

  componentDidUpdate(previousProps, previousState) {
    if (previousProps.date !== this.props.date) {
      this.setDate(this.props.date);
    }
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value }, () => {
      this.props.onChange(this.state.year, this.state.month, this.state.day);
      this.setState({
        date: this.state.year + "-" + this.state.month + "-" + this.state.day,
      });
    });
  }

  render() {
    return (
      <div id={this.props.id} className="date-picker-select">
        <select name="day" value={this.state.day} onChange={this.onChange}>
          {days}
        </select>
        <select name="month" value={this.state.month} onChange={this.onChange}>
          {months}
        </select>
        <select name="year" value={this.state.year} onChange={this.onChange}>
          {years}
        </select>
      </div>
    );
  }
}

export default DatePickerSelect;
