import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";

class OldBrowser extends Component {
    render() {
      return (
        <div id='error' className='page'>
          <section>
            <h1>
              <FormattedMessage id='error.oldbrowser.title' />
            </h1>
            <p>
              <FormattedMessage
                id='error.oldbrowser.p1'
                values={{
                      GoogleChromeLink: <a href='https://google.com/chrome'>Google Chrome</a>
                    }}
              />
            </p>
          </section>
        </div>
      );
    }
  }
  
  export default OldBrowser;