import React from "react";
import { useIntl } from "react-intl";
const Duration = (props) => {
  const { assesment, assesmentTest } = props;
  
  const intl = useIntl();
  const is_adapted = assesment.candidate_adapted_measures_factor && assesmentTest.adapted_measures_allowed;
  const adapted_extra_time = is_adapted ? assesmentTest.test.duration_max * assesment.candidate_adapted_measures_factor / 100 : 0;
  const is_timed = assesmentTest.test.duration_max ? true : false;
  const duration = assesmentTest.test.duration_max ? assesmentTest.test.duration_max + adapted_extra_time : assesmentTest.test.duration_approx;


  if (duration) {
    const duration_string = getDurationString(duration);
    return (
      <span>
        {duration_string} 
        { is_timed &&
          <>
            &nbsp;(
            <strong>
              {intl.formatMessage({ id: "home.questionnaires-chronometre" })}
            </strong>
            )
          </>
        }
      </span>
    )
  }

  return <span className="text-muted">--</span>;
};

const getDurationString = (duration) => {
  const min = Math.trunc(duration / 60);
  // const sec = duration % 60;
  let duration_string = min ? min + " min." : "";
  // duration_string += sec ? " " + sec + " sec." : "";
  return duration_string;
}

export default Duration;