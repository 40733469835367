import axios from "axios";

let configWithBearerToken = undefined;
const baseURL = process.env.REACT_APP_API_URL;
const timeout = 30000;
const api = axios.create({
  baseURL: baseURL,
  timeout: timeout,
  headers: { "Content-Type": "application/json" },
});

export const setConfigWithBearerTokenToken = (token) => {
  configWithBearerToken = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
};

export const unsetConfigWithBearerTokenToken = () => {
  configWithBearerToken = {
    headers: {
      "Content-Type": "application/json",
    },
  };
};

export const tokenLogin = (token) => {
  return api.post("/tokenLogin", { login_token: token }).then((response) => {
    setConfigWithBearerTokenToken(response.data.api_token);
    return response;
  });
};

export const codePermLogin = (codePerm) => {
  return api
    .post("/codePermLogin", { code_perm: codePerm })
    .then((response) => {
      setConfigWithBearerTokenToken(response.data.api_token);
      return response;
    });
};

export const assesmentLogin = (signedUrl) => {
  return api
    .get(`/assesment-login/${signedUrl}`)
    .then((response) => {
      setConfigWithBearerTokenToken(response.data.api_token);
      return response;
    })
    .catch((error) => {
      return Promise.reject(error);
    })
};

export const logout = () => {
  return api.get("/logout").then((response) => {
    unsetConfigWithBearerTokenToken();
    return response;
  });
};

export const candidate = {
  get: () => {
    let path = "/candidate";
    return api.get(path, configWithBearerToken).then((response) => {
      return response;
    });
  },
  put: (id, payload) => {
    return api
      .put("/candidate/" + id, payload, configWithBearerToken)
      .then((response) => {
        return response;
      });
  },
};

export const assesment = {
  get: (id) => {
    let path = `/assesment/${id}`;
    return api.get(path, configWithBearerToken).then((response) => {
      return response;
    });
  },
  put: (id, payload) => {
    let path = `/assesment/${id}`;
    return api.put(path, payload, configWithBearerToken).then((response) => {
      return response;
    });
  },
  getNextAssesmentTest: (id) => {
    let path = `/assesment/${id}/next-assesment-test`;
    return api.get(path, configWithBearerToken).then((response) => {  
      return response;
    });
  },
  completed: (id) => {
    return api
      .get("/assesment/" + id + "/completed", configWithBearerToken)
      .then((response) => {
        return response;
      });
  },
};

export const assesmentTest = {
  get: (id) => {
    return api
      .get("/assesment-test/" + id, configWithBearerToken)
      .then((response) => {
        return response;
      });
  },
  put: (id, payload) => {
    return api
      .put("/assesment-test/" + id, payload, configWithBearerToken)
      .then((response) => {
        return response;
      });
  },
  usePrevAnswers: (id_assesment_test, payload) => {
    return api
      .post(
          "/assesment-test/" +
          id_assesment_test +
          "/use-previous-answers",
        payload,
        configWithBearerToken
      )
      .then((response) => {
        return response;
      });
  },
  completed: (id, payload) => {
    return api
      .post(
        "/assesment-test/" + id + "/completed",
        payload,
        configWithBearerToken
      )
      .then((response) => {
        return response;
      });
  },
};

export const assesmentTestQuestion = {
  put: (id, payload) => {
    return api
      .put(
        "/assesment-test-question/" + id,
        payload,
        configWithBearerToken
      )
      .then((response) => {
        return response;
      });
  },
};
