import React from "react";
import { FormattedMessage } from "react-intl";
import { useTranslation } from "hooks/useTranslation";

function Header(props) {
  const { assesment, assesmentTest, assesmentTests, remote } = props;
  const testTitle = useTranslation("name", assesmentTest.test);

// Define index of current test and total number of tests
// to display progress in the header
  let index,
    nbTests = undefined;
  if (assesmentTests) {
    // If usersession is remote, only include remote tests...
    if (remote) {
      let remoteAssesmentTests = assesmentTests.filter((item) => item.test.remote === 1);
      index = remoteAssesmentTests
        .map(function (assesmentTest) {
          return assesmentTest.id;
        })
        .indexOf(assesmentTest.id);
      nbTests = remoteAssesmentTests.length;
    } else {
      index = assesmentTests
        .map(function (assesmentTest) {
          return assesmentTest.id;
        })
        .indexOf(assesmentTest.id);
      nbTests = assesmentTests.length;
    }
  }

  const DurationPill = () => {
    const duration_approx = assesmentTest.test.duration_approx;
    const duration_max = assesmentTest.test.duration_max;
    
    if(!duration_approx && !duration_max) return (<span></span>);
    
    const duration = assesmentTest.test.duration_max ? assesmentTest.test.duration_max : assesmentTest.test.duration_approx;
    const min = Math.trunc(duration / 60);
    let duration_string = min ? min + " min." : "";
      
    // Uncomment this block if you want to display seconds as well
    // const sec = duration % 60;
    // duration_string += sec ? " " + sec + " sec." : "";

    return (
      <div
        id={duration_max ? "intro-timed-pill" : "intro-approx-pill"}
        className={duration_max ? "intro-pill intro-timed-pill" : "intro-pill intro-approx-pill"}
      >
        {duration_string}
      </div>
    );
  };

  return (
    <div id="instructions-header">
      {assesmentTests && index >= 0 && nbTests && nbTests >= 1 && (
        <div id="intro-session-progress">
          Test {index + 1} <FormattedMessage id="progress.of" /> {nbTests}
        </div>
      )}
      <h1 id="intro-test-title">
        {testTitle}
        {/* {test.duration_max && (
          <p id="intro-subtitle">
            <FormattedMessage id="user_test.questionnaires-chronometre" />
          </p>
        )} */}
      </h1>
      <DurationPill />
    </div>
  );
}

export default Header;
