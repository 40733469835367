import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

class LayoutCollege20 extends Component {
  render() {
    const { testCompletedHandler } = this.props;
    return (
      <div className='page'>
        <section>
          <h1>
            <FormattedMessage id='questionnaire.completed.college.title' />
          </h1>
          <p>
            <FormattedMessage id='questionnaire.completed.college.p1' />
          </p>
          <p>
            <FormattedMessage 
              id='questionnaire.completed.college.p2'
              values={
                {Previous: <strong>{this.props.intl.formatMessage({id: "questionnaire.completed.college.p2_previous"})}</strong>}
              }
            />
          </p>
          <p>
            <FormattedMessage id='questionnaire.completed.college.p3'
              values={
                {Submit: <strong>{this.props.intl.formatMessage({id: "questionnaire.completed.college.p3_submit"})}</strong>}
              }
            />
          </p>
          <p>
            <FormattedMessage id='questionnaire.completed.college.p4'
              values={
                {Important: <strong>{this.props.intl.formatMessage({id: "questionnaire.completed.college.p4_important"})}</strong>}  
              }
             />
          </p>
          <button className='btn-lg btn-primary' onClick={testCompletedHandler}>
            <FormattedMessage id='questionnaire.completed.college.button.submit_questionnaire' />
          </button>
        </section>
      </div>
    );
  }
}

export default injectIntl(LayoutCollege20);
