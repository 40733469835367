import { useTranslation } from 'hooks/useTranslation';

const Label = ({item}) => {
  const label = useTranslation("text", item?.question);

  return (
    <div>
      <h2 id="main-card-label">{ label }</h2>
    </div>
  )
}

export default Label;