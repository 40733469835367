import { FormattedMessage, useIntl } from "react-intl";
import { connect } from "react-redux";
import Example from "../../test/intro/Example";
import assesmentTest from "./assesmentTest.json";

const DemoAcademic10TestIntro =  (props) => {
  const intl = useIntl();
  const { lang } = props;
  return (
    <section id="demo-academic10-test-intro">
      {lang.current_lang === 'en' &&
      <h1>{assesmentTest['test']?.nom_en}</h1>
    }
    {lang.current_lang != 'en' &&
      <h1>{assesmentTest['test']?.nom}</h1>
  }
      <h3>
        <FormattedMessage id="user-test.title.instructions" />
      </h3>
      <div
        id="intro-instructions"
        dangerouslySetInnerHTML={{ __html: assesmentTest['test']['message_intro_'+lang.current_lang] }}
      />
      
      <div style={{border: "1px solid lightgrey", padding:"20px", marginBottom: "60px"}}>
      <Example test={assesmentTest['test']} />
      </div>

      <p><FormattedMessage id="demo.academic10.test.intro.p1" values={{
        StartLabel: <strong>{intl.formatMessage({id:"demo.academic10.test.intro.p1_start_label"})}</strong>}}/></p>
      <div className="text-center">
        <button className="btn btn-primary btn-lg" onClick={()=>props.next()}><FormattedMessage id="user_test.label.start-test" /></button>
      </div>
    </section>
  );
};

function mapStateToProps(state){
  return {
    lang: state.lang,
  }
}

export default connect(mapStateToProps)(DemoAcademic10TestIntro);