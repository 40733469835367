// These must be the first lines in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
//

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from "./redux/store";

import App from './App';

const rootElement = document.getElementById('root')
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  rootElement
)
