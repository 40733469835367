import React, { Component } from "react";
import * as AUDIENCES from "constants/audiences";
import { FormattedMessage } from "react-intl";
import TestItemsTable from "../home/TestItemsTable";
import { connect } from "react-redux";
import * as authActions from "../../redux/actions/auth/index";
import * as langActions from "../../redux/actions/lang/index";
import * as assesmentActions from "../../redux/actions/assesment/index";
import { bindActionCreators } from "redux";

class SessionConclusion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assesment: undefined,
      candidate: undefined,
      lang: undefined,
    };
  }

  componentDidMount() {
    if (!this.props.auth || this.props.auth.isLoggedIn !== true) {
      this.props.history.push("/connexion-requise");
      return;
    }

    // Check if all tests in assesment are completed...
    try {
      let notCompletedAssesmentTest = undefined;
      if (this.props.assesment.currently_remote) {
        notCompletedAssesmentTest = this.props.assesment.assesment_tests.find(
          (assesmentTest) =>
            assesmentTest.dt_termine === null && assesmentTest.test.remote === 1
        );
      } else {
        notCompletedAssesmentTest = this.props.assesment.assesment_tests.find(
          (assesmentTest) => assesmentTest.dt_termine === null
        );
      }
      if (notCompletedAssesmentTest) {
        this.props.history.push("/assesment-test");
        return;
      }
    } catch (e) {
      this.props.history.push("/error"); // Why are you here!?
      return;
    }

    // Map props to local state before logout (logout will clear redux store props)
    this.setState(
      {
        assesment: this.props.assesment,
        candidate: this.props.candidate,
        lang: this.props.lang
      },
      () => {
        this.props.actions.logout();
        this.props.actions.setCandidatePrefLang(this.state.lang.current_lang);
      }
    );
  }
  render() {
    const { assesment, candidate, lang } = this.state;
    
    if (!assesment || !candidate) {
      return <div>Loading...</div>;
    }

    let audience;

    switch (assesment.evaluation_session.audience_type_id) {
      case AUDIENCES.ACADEMIC_PRIMARY:
        audience = "academic10";
        break;
      case AUDIENCES.ACADEMIC_COLLEGE:
        audience = "college";
        break;
      default:
        audience = "professional";
        break;
    }

    let testsInPersonRemaining = false;
    if (assesment.currently_remote === true) {
      testsInPersonRemaining = assesment.assesment_tests.some(
        (assesmentTest) => assesmentTest.dt_termine === null && assesmentTest.test.remote === 0
      );
    }
    return (
      <div className="page">
        <section>
          <h1>Conclusion</h1>
          <p>
            <FormattedMessage id={`sessionConclusion.${audience}.p1`} />
          </p>
          <p>
            <FormattedMessage
              id={`sessionConclusion.${audience}.p2`}
              values={{
                in_person: testsInPersonRemaining ? true : false,
              }}
            />
          </p>
          <p>
            <FormattedMessage id={`sessionConclusion.${audience}.p3`} />
          </p>

          <div>
            {assesment && (
              <TestItemsTable assesment={assesment} />
            )}
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    candidate: state.candidate,
    auth: state.auth,
    lang: state.lang,
    assesment: state.assesment,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, authActions, assesmentActions, langActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SessionConclusion);
