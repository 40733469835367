import React from "react";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import * as authActions from "../../redux/actions/auth/index";
import { FormattedMessage } from "react-intl";
import { useHistory, useLocation } from "react-router";
import LangSelector from "./LangSelector";

const Header = (props) => {
  const candidate = useSelector((state) => state.candidate);
  const auth = useSelector((state) => state.auth);
  const langStore = useSelector((state) => state.lang);
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(authActions.logout());
  };

  const gotoRoot = (e) => {
    e.preventDefault();
    if (location?.pathname?.startsWith("/demo/")) {
      return history.go(0);
    }

    return history.push("/");
  };

  let localized_eprofil =
    langStore.current_lang === "en" ? "TalenTest" : "TalenTest";
  return (
    <header>
      <a href="#" onClick={(e) => gotoRoot(e)} className="partner">
        <i className="icon-edit"></i>&nbsp;
        <FormattedMessage id="header.title" /> | {localized_eprofil}
      </a>
      <ul className="no-bullet horizontal header-menu">
        <li>
          {candidate.first_name} {candidate.last_name}
        </li>
        {auth.isLoggedIn && (
          <li onClick={logout}>
            <i className="icon-logout"></i>
          </li>
        )}
        {(langStore?.forced_lang === null) ? (
          <li>
            <LangSelector />
          </li>
        ) : null }
      </ul>
    </header>
  );
};

export default Header;
