import * as actions from "./actionTypes";

const setCandidatePrefLangRequested = (lang) => ({
    type: actions.SET_CANDIDATE_PREF_LANG_REQUEST,
    lang,
});
export const setCandidatePrefLang = (lang) => (dispatch) => {
    dispatch(setCandidatePrefLangRequested(lang));
}


