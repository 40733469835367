import * as actionTypes from "../actions/assesmentTest/actionTypes";
import * as atq_actionTypes from "../actions/assesmentTestQuestion/actionTypes";
import { combineReducers } from "redux";

export const assesmentTest = (state = [], action) => {
  switch (action.type) {
    case actionTypes.FETCH_ASSESMENT_TEST_SUCCESSFUL: {
      let elapsed_time = action.response.elapsed_time;
      // Make sure the server response is about the same test as the current state in localStorage (i.e. id from test in localStorage matches id of test from server response)
      if (
        state.id === action.response.id &&
        state.hasOwnProperty("elapsed_time")
      ) {
        // Compare elapsed_time from localStorage (hydrated from "state") and elapsed_time from server response
        elapsed_time = Math.max(
          state.elapsed_time,
          action.response.elapsed_time
        );
      }

      // Overridden elapsed time by admin?
      if (
        action.response.hasOwnProperty("time_overide") &&
        action.response.time_overide !== null &&
        Number(action.response.time_overide) >= 0
      ) {
        action.response.elapsed_time = action.response.time_overide;
      }
      return {
        ...state,
        ...action.response,
      };
    }
    case actionTypes.UPDATE_ASSESMENT_TEST_ELAPSED_TIME: {
      if (state.id === action.id) {
        return {
          ...state,
          elapsed_time: action.time,
        };
      } else {
        return state;
      }
    }
    case atq_actionTypes.UPDATE_ASSESMENT_TEST_QUESTION_SUCCESSFUL: {
      //https://daveceddia.com/react-redux-immutability-guide/#redux-update-an-object-in-an-object
      const { id, open_answer, answer_explanation, answers } = action.response;
      return {
        ...state,
        assesment_test_questions: state.assesment_test_questions.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              open_answer,
              answer_explanation,
              answers
            };
          }
          return item;
        }),
      };
    }
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ASSESMENT_TEST_REQUEST:
      return true;
    case actionTypes.FETCH_ASSESMENT_TEST_SUCCESSFUL:
    case actionTypes.FETCH_ASSESMENT_TEST_FAILURE:
      return false;
    default:
      return state;
  }
};
const isUpdating = (state = false, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_ASSESMENT_TEST_REQUEST:
      return true;
    case actionTypes.UPDATE_ASSESMENT_TEST_SUCCESSFUL:
    case actionTypes.UPDATE_ASSESMENT_TEST_FAILURE:
      return false;
    default:
      return state;
  }
};
const error = (state = null, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ASSESMENT_TEST_SUCCESSFUL:
    case actionTypes.UPDATE_ASSESMENT_TEST_SUCCESSFUL:
    case actionTypes.FETCH_ASSESMENT_TEST_REQUEST:
    case actionTypes.UPDATE_ASSESMENT_TEST_REQUEST:
      return null;
    case actionTypes.FETCH_ASSESMENT_TEST_FAILURE:
    case actionTypes.UPDATE_ASSESMENT_TEST_FAILURE:
      return action.error;
    default:
      return state;
  }
};

const assesmentTestStatus = combineReducers({
  isFetching,
  isUpdating,
  error,
});
export default assesmentTestStatus;
export const getError = (state) => state.error;
export const getIsFetching = (state) => state.isFetching;
export const getIsUpdating = (state) => state.isUpdating;
