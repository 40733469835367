import React, { useState } from "react";
import DemoAcademic10Conclusion from "./DemoAcademic10Conclusion";
import DemoAcademic10Home from "./DemoAcademic10Home";
import DemoAcademic10Test from "./DemoAcademic10Test";
import DemoAcademic10TestIntro from "./DemoAcademic10TestIntro";

const DemoAcademic10 = (props) => {

  const totalSteps = 4;
  const [currentStep, setCurrentStep] = useState(1);


  const nextStepCB = ()=>{
    document.getElementById("app-wrapper")?.scrollTo(0,0);
    setCurrentStep( currentStep+1 > totalSteps ? 1 : currentStep+1 );
  }
  const prevStepCB = ()=>{
    document.getElementById("app-wrapper")?.scrollTo(0,0);
    setCurrentStep( currentStep-1 > 0 ? currentStep-1 : 0 );
  }

  return (
    <div id="demo-Academic10">
      {currentStep === 1 &&
        <DemoAcademic10Home step={currentStep} next={nextStepCB} />
      }
      {currentStep === 2 &&
        <DemoAcademic10TestIntro step={currentStep} next={nextStepCB} prev={prevStepCB} />
      }
      {currentStep === 3 &&
        <DemoAcademic10Test step={currentStep} next={nextStepCB} pref_lang={props.pref_lang} />
      }
      {currentStep === 4 &&
        <DemoAcademic10Conclusion step={currentStep} next={nextStepCB} pref_lang={props.pref_lang} />
      }
    </div>
  );
};

export default DemoAcademic10;