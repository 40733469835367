import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class Mobile extends Component {
  render() {
    const width = window.innerWidth;

    return (
      <div id="error" className="page">
        <section>
          <h1>
            <FormattedMessage id="error.mobile.title" />
          </h1>
          <p>
            <FormattedMessage id="error.mobile.p1" />
          </p>
          {/* Display mobile width */}
          <p className="text-muted">({width}px)</p>
        </section>
      </div>
    );
  }
}

export default Mobile;
