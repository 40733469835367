import React, { useEffect } from "react";
import { useParams } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as authActions  from "../../redux/actions/auth/index";
import * as langActions from "../../redux/actions/lang/index";
import DemoProfessional from "./demoProfessional/DemoProfessional";
import DemoCollege from "./demoCollege/DemoCollege";
import DemoAcademic10 from "./demoAcademic10/DemoAcademic10";

const Demo = (props) => {
  const params = useParams();
  const audience = params?.audience;

  const { lang } = props;
  const { setCandidatePrefLang, logout } = props.actions;

  useEffect(()=>{
    logout(); // Just in case candidate was previously logged in
    setCandidatePrefLang(params.lang);

  },[]);

  if(audience === "acad"){
    return  <div id="demo" className="page"><DemoAcademic10 pref_lang={lang.current_lang} /></div>;
  }
  
  if(audience === "univ"){
    return <div id="demo" className="page"><DemoCollege pref_lang={lang.current_lang} /></div>;
  }

  // Default...
  return <div id="demo" className="page"><DemoProfessional /></div>;

};

function mapStateToProps(state){
  return {
    lang: state.lang,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign({}, langActions, authActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Demo);
