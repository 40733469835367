import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class NotFound extends Component {
  render() {
    return (
      <div id="intro" className="page">
        <section>
          <h1>
            <FormattedMessage id="error.not-found.title" />
          </h1>
          <p>
            <a href="/">
              <FormattedMessage id="error.not-found.p" />
            </a>
          </p>
        </section>
      </div>
    );
  }
}

export default NotFound;
