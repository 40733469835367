import React from 'react';
import { useSelector } from 'react-redux';
import SortingButton from './SortingButton';

const SortingButtons = ({item, save}) => {
  const assesmentTest = useSelector((state) => state.assesmentTest);
  return(
    <div id="sorting-buttons">
        {assesmentTest.test.test_baskets[0].basket_details.map(
        (dropzone, index) => {
          return (
            <SortingButton
              key={'sorting-button-'+index}
              dropzone={dropzone}
              value={dropzone.answer_definition_choice.id}
              onClickFunction={(e)=>{save(item.id, {answers: [dropzone.answer_definition_choice.id]})}}
              disabled={!item} />
          )}
        )}
    </div>
  )
}

export default SortingButtons