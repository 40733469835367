import React from "react";
import * as AUDIENCES from "constants/audiences";
import * as candidateActions from "../../redux/actions/candidate/index";
import * as langActions from "../../redux/actions/lang/index";
import * as assesmentActions from "../../redux/actions/assesment/index";
import * as fromCandidateReducer from "../../redux/reducers/candidate";
import * as fromAssesmentReducer from "../../redux/reducers/assesment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { injectIntl } from "react-intl";
import LoadingScreen from "../helpers/loadingScreen";
import FetchError from "../error/FetchError";
import LayoutProfessional from "./LayoutProfessional";
import LayoutCollege20 from "./LayoutCollege-20";
import LayoutAcademic10 from "./LayoutAcademic-10";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.startBtnClicked = this.startBtnClicked.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchData();
  }

  fetchData() {
    const { fetchCandidate, fetchAssesment } = this.props.actions;
    fetchCandidate();
    fetchAssesment(this.props.assesment.id);
  }

  startBtnClicked() {
    if (document.getElementById("test-lang-select")) {
      const { setCandidatePrefLang } = this.props.actions;
      setCandidatePrefLang(document.getElementById("test-lang-select").value);
    }
  }

  render() {
    const { lang } = this.props;
    const { candidate, candidate_isFetching, candidate_error } = this.props;

    const {
      assesment,
      assesment_isFetching,
      assesment_error,
    } = this.props;

    if (candidate_isFetching || assesment_isFetching) {
      return (
        <div id="home" className="page">
          <LoadingScreen />
        </div>
      );
    }

    if (candidate_error) {
      return <FetchError error={candidate_error} redirect={true} />;
    }

    if (assesment_error) {
      return <FetchError error={assesment_error} redirect={true} />;
    }

    if (!assesment?.evaluation_session || !candidate) {
      return (
        <div id="home" className="page">
          <LoadingScreen />
        </div>
      );
    }

    return (
      <>
        {assesment?.evaluation_session.audience_type_id === AUDIENCES.ACADEMIC_PRIMARY && (
          <LayoutAcademic10
            candidate={candidate}
            assesment={assesment}
            lang={lang}
            onStartBtnClicked={this.startBtnClicked}
          />
        )}
        {assesment?.evaluation_session.audience_type_id === AUDIENCES.ACADEMIC_COLLEGE && (
          <LayoutCollege20
            candidate={candidate}
            assesment={assesment}
            lang={lang}
            onStartBtnClicked={this.startBtnClicked}
          />
        )}
        {assesment &&
          assesment?.evaluation_session.audience_type_id != AUDIENCES.ACADEMIC_PRIMARY &&
          assesment?.evaluation_session.audience_type_id != AUDIENCES.ACADEMIC_COLLEGE && (
            <LayoutProfessional
              candidate={candidate}
              assesment={assesment}
              lang={lang}
              onStartBtnClicked={this.startBtnClicked}
            />
          )}
        <div className="text-center">
          <a href={this.props.intl.formatMessage({ id: "home.translate_url" })}>
            <img
              src={this.props.intl.formatMessage({
                id: "home.translate_img_src",
              })}
              alt={this.props.intl.formatMessage({
                id: "home.translate_img_alt",
              })}
              style={{
                width: this.props.intl.formatMessage({
                  id: "home.translate_img_width",
                }),
              }}
            />
          </a>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.lang,
    candidate: state.candidate,
    candidate_error: fromCandidateReducer.getError(state.candidateStatus),
    candidate_isFetching: fromCandidateReducer.getIsFetching(
      state.candidateStatus
    ),
    assesment: state.assesment,
    assesment_error: fromAssesmentReducer.getError(
      state.assesmentStatus
    ),
    assesment_isFetching: fromAssesmentReducer.getIsFetching(
      state.assesmentStatus
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(
        {},
        candidateActions,
        assesmentActions,
        langActions
      ),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home));
