import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import TokenLogin from "./tokenLogin/TokenLogin";
import Home from "./home/Home";
import Consent from "./consent/Consent";
import Test from "./test/Test";
import Questionnaire from "./test/questionnaire/Questionnaire";

import { NoLocalStorage } from "./error/NoLocalStorage";
import ConnexionRequise from "./error/ConnexionRequise";
import supportedBrowsers from "../supportedBrowsers";
import OldBrowser from "./error/OldBrowser";
import NotFound from "./helpers/NotFound";
import CodePermLogin from "./login/CodePermLogin";
import SessionConclusion from "./test/SessionConclusion";
import FetchError from "./error/FetchError";
import HowToDisableTranslator from "./error/HowToDisableTranslator";
import Demo from "./demo/Demo";
import Mobile from "./error/Mobile";
import Logout from "./logout/Logout";
import AssesmentLogin from "./login/AssesmentLogin";
import Completed from "./test/questionnaire/components/completed/Completed";

const Main = () => {
  // console.log(navigator.userAgent);
  if (!supportedBrowsers.test(navigator.userAgent)) {
    return (
      <main className="page-wrapper">
        <OldBrowser />
      </main>
    );
  }
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  // For this to work,
  // `<meta name="viewport" content="width=device-width, initial-scale=1">`
  // is required in the `<head>` of the public/index.html document.
  const isMobile = width < 768;
  if (isMobile) {
    return (
      <main className="page-wrapper">
        <Mobile />
      </main>
    );
  }
  return (
    <main className="page-wrapper">
      <Switch>
        <Route exact path="/login/:token" component={TokenLogin} />
        <Route exact path="/api/v1/assesment-login/:signedUrl" component={AssesmentLogin} />
        <Route exact path="/code-permanent" component={CodePermLogin} />
        <Route exact path="/permanent-code" component={CodePermLogin} />
        <Route exact path="/codigo-permanente" component={CodePermLogin} />
        <Route exact path="/connexion-requise" component={ConnexionRequise} />
        <Route exact path="/demo/:audience/:lang" component={Demo} />
        <Route exact path="/noLocalStorage" component={NoLocalStorage} />
        <Route exact path="/browser-outdated" component={OldBrowser} />
        <Route exact path="/traducteur" component={HowToDisableTranslator} />
        <Route exact path="/translator" component={HowToDisableTranslator} />
        <Route exact path="/traductor" component={HowToDisableTranslator} />
        <Route exact path="/error" component={FetchError} error={null} />
        <Route exact path="/logout" component={Logout} />

        <PrivateRoute exact path="/" component={Home} />
        <PrivateRoute exact path="/consentement" component={Consent} />
        <PrivateRoute exact path="/assesment-test" component={Test} />
        <Route exact path="/conclusion" component={SessionConclusion} />
        <PrivateRoute
          exact
          path="/assesment-test/:id/questionnaire"
          component={Questionnaire}
        />
        <PrivateRoute
          exact
          path="/assesment-test/:id/completed"
          component={Completed}
        />
        <Route exact path="/not-found" component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </main>
  );
};

export default Main;
