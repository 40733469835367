import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const ProgressBar = ({currentIndex, total}) => {

  const [percentProgress, setPercentProgress] = useState(0);

  useEffect(() => {
    setPercentProgress(
      ((Math.min(currentIndex, total) /
      total) *
      100
    ));
  }, [currentIndex]);

  return (
    <div id="progress-bar" className="progress-bar">
      <div id="progress-bar">
        <div id="progress" style={{ width: `${percentProgress}%` }}/>
        <div id="count">
          {currentIndex ?? "--"}{" "}
          <FormattedMessage id="progress.of" defaultMessage="de" />{" "}
          {total ?? "--"}
        </div>
      </div>
    </div>
  );
}

export default ProgressBar;
