import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router";
import { connect } from "react-redux";

const PrivateRoute = ({ component: Component, auth, props, ...rest }) => {
  return auth.isLoggedIn ? (
    <Route {...rest} render={(allProps) => <Component {...allProps} />} />
  ) : (
    <Redirect to="/connexion-requise" />
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
