import React from 'react';
import { FormattedMessage } from 'react-intl';

const Explanation = ({current, value, onChange}) =>{

  if(current.question.answer_definition.answer_mode_id !== "REX") return null;
  return(
    <div id="answer-explanation">
      <label htmlFor=""><FormattedMessage id="questionnaire.label.answer-explanation"/></label>
      <textarea 
        id="answer-explanation-textarea"
        name="answer-explanation-textarea"
        onChange={ onChange }
        value={ value ?? undefined }
        maxLength="2000">
      </textarea>
      <p className="help-text text-right">Max. 2000</p>
    </div>
  );
}

export default Explanation;