import React, { Component } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import TestItemRow from "./TestItemRow";

const TestItemsTable = (props) => {

  const { assesment } = props;

  return (
    <div className="table-wrapper">
      <table className="table-listing">
        <thead>
          <tr>
            <FormattedMessage id="app.global.label.name">
              {(txt) => <th>{txt}</th>}
            </FormattedMessage>
            <FormattedMessage id="app.global.label.approx_time">
              {(txt) => <th>{txt}</th>}
            </FormattedMessage>
            <FormattedMessage id="app.global.label.status">
              {(txt) => <th>{txt}</th>}
            </FormattedMessage>
          </tr>
        </thead>
        <tbody>
          { assesment.assesment_tests.map(
            (assesment_test) => {
              return <TestItemRow key={assesment_test.id} assesment={assesment} assesmentTest={assesment_test} />
            }
          )}
        </tbody>
      </table>
    </div>
  );
}

export default TestItemsTable;