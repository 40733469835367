import React, { useState } from "react";
import { connect } from "react-redux";
import DemoProfessionalConclusion from "./DemoProfessionalConclusion";
import DemoProfessionalHome from "./DemoProfessionalHome";
import DemoProfessionalTest from "./DemoProfessionalTest";
import DemoProfessionalTestIntro from "./DemoProfessionalTestIntro";

const DemoProfessional = (props) => {

  const totalSteps = 4;
  const [currentStep, setCurrentStep] = useState(1);


  const nextStepCB = ()=>{
    document.getElementById("app-wrapper")?.scrollTo(0,0);
    setCurrentStep( currentStep+1 > totalSteps ? 1 : currentStep+1 );
  }
  const prevStepCB = ()=>{
    document.getElementById("app-wrapper")?.scrollTo(0,0);
    setCurrentStep( currentStep-1 > 0 ? currentStep-1 : 0 );
  }

  return (
    <div id="demo-professional">
      {currentStep === 1 &&
        <DemoProfessionalHome step={currentStep} next={nextStepCB} pref_lang={props.lang.current_lang} />
      }
      {currentStep === 2 &&
        <DemoProfessionalTestIntro step={currentStep} next={nextStepCB} prev={prevStepCB} pref_lang={props.lang.current_lang} />
      }
      {currentStep === 3 &&
        <DemoProfessionalTest step={currentStep} next={nextStepCB} pref_lang={props.lang.current_lang} />
      }
      {currentStep === 4 &&
        <DemoProfessionalConclusion step={currentStep} next={nextStepCB} pref_lang={props.lang.current_lang} />
      }
    </div>
  );
};

function mapStateToProps(state) {
  return {
    lang: state.lang
  };
}

export default connect(mapStateToProps, null)(DemoProfessional);