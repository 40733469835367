import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { helpers } from "../helpers/helpers";
import * as langActions from "../../redux/actions/lang/index";
import * as translationActions from "../../redux/actions/translation/index";
import LangSelectorDropdown from "./LangSelectorDropdown";

const LangSelector = () => {
  const langStore = useSelector((state) => state.lang);
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();

  const switchLang = (toLang) => {
    dispatch(langActions.setCandidatePrefLang(toLang));
    dispatch(translationActions.resetTranslationMissing());
    setVisible(false);
  };

  return (
    <>
      <button id="switch-lang-btn" onClick={() => setVisible(!visible)}>
        {helpers.capitalize(langStore.candidate_pref_lang ?? langStore.current_lang)}
      </button>
      {visible && <LangSelectorDropdown setCandidatePrefLang={switchLang} />}
    </>
  );
};

export default LangSelector;
