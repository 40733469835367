import React, { Component } from 'react';

class LoadingScreen extends Component {

  render() {
    return (
      <div id="loading-animation-wrapper">
        <div id="loading-animation">
        <svg className="spinner" viewBox="0 0 50 50"><circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle></svg>
        </div>
      </div>
    )
  }
}

export default LoadingScreen