import React, { useState } from "react";
import DemoCollegeConclusion from "./DemoCollegeConclusion";
import DemoCollegeHome from "./DemoCollegeHome";
import DemoCollegeTest from "./DemoCollegeTest";
import DemoCollegeTestIntro from "./DemoCollegeTestIntro";

const DemoCollege = (props) => {

  const totalSteps = 4;
  const [currentStep, setCurrentStep] = useState(1);


  const nextStepCB = ()=>{
    document.getElementById("app-wrapper")?.scrollTo(0,0);
    setCurrentStep( currentStep+1 > totalSteps ? 1 : currentStep+1 );
  }
  const prevStepCB = ()=>{
    document.getElementById("app-wrapper")?.scrollTo(0,0);
    setCurrentStep( currentStep-1 > 0 ? currentStep-1 : 0 );
  }

  return (
    <div id="demo-College">
      {currentStep === 1 &&
        <DemoCollegeHome step={currentStep} next={nextStepCB} pref_lang={props.pref_lang} />
      }
      {currentStep === 2 &&
        <DemoCollegeTestIntro step={currentStep} next={nextStepCB} prev={prevStepCB} pref_lang={props.pref_lang} />
      }
      {currentStep === 3 &&
        <DemoCollegeTest step={currentStep} next={nextStepCB} pref_lang={props.pref_lang} />
      }
      {currentStep === 4 &&
        <DemoCollegeConclusion step={currentStep} next={nextStepCB} pref_lang={props.pref_lang} />
      }
    </div>
  );
};

export default DemoCollege;