import React, { useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as translationActions from "../redux/actions/translation/index";

const TranslationMissing = () => {
  const candidate_pref_lang = useSelector((state) => state.lang.candidate_pref_lang);
  const isTranslationMissing = useSelector((state) => state.translation.is_missing);
  const dispatch = useDispatch();
  const [isOpened, setIsOpened] = useState(isTranslationMissing);
  const messages = {
    es: "Algunos elementos de la página no están disponibles en el idioma seleccionado.",
    en: "Some elements of the page are not available in the selected language.",
    fr: "Certains éléments de la page ne sont pas disponibles dans la langue sélectionnée.",
  };

  // Upon location change, reset
  const location = useLocation();
  useEffect(() => {
    dispatch(translationActions.resetTranslationMissing());
  }, [location]);

  // Upon isTranslationMissing change, update isOpened
  useEffect(() => {
    setIsOpened(isTranslationMissing);
  }, [isTranslationMissing]);

  if (isTranslationMissing === false || isOpened === false) {
    return null;
  }

  return (
    <div className="callout">
      <div>
        {messages[candidate_pref_lang]}
      </div>
      <div>
        <button className="close-btn" onClick={()=>setIsOpened(false)}>
        <i className="icon-cancel"></i>
        </button>
      </div>
    </div>
  );
};

export default withRouter(TranslationMissing);
