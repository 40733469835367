export const helpers =  {
    isApostropheNeeded: (s) => {
        if(typeof s === 'undefined' || s === 'undefined'){
            return false
        }
        if(['A','E','I','O','U','Y','H'].includes(s.charAt(0).toUpperCase())){
            return true
        }
        return false
    },
    capitalize: (s) => {
        if(typeof s === 'undefined' || s === 'undefined' || s === null){
            return ''
        }
        return s.charAt(0).toUpperCase() + s.slice(1)
    },
    stripHtmlTags: (str) => {
        if ((str===null) || (str===''))
            return "";
        else
        str = str.toString();
        return str.replace(/<[^>]*>/g, '');
    },
    strToDate(dtStr) {
        let dateParts = dtStr.split("-");
        let timeParts = dateParts[2].split(" ")[1].split(":");
        dateParts[2] = dateParts[2].split(" ")[0];
        // month is 0-based, that's why we need dataParts[1] - 1
        return new Date(+dateParts[0], dateParts[1] - 1, +dateParts[2], timeParts[0], timeParts[1], timeParts[2]);     
    },
    replaceSapceBeforeColon: (str) => {
        // Althought they look the same, the second argument is a non-breaking space (alt+space on Mac)
        return str.replace(" :", " :");
    }
}