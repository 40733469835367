let audio = new Audio();

export const stopAudio = () => {
  if (audio.duration > 0 && !audio.paused) {
    audio.pause();
  }
};

export const playAudio = (e) => {
  e.preventDefault();

  if (audio.duration > 0 && !audio.paused) {
    audio.pause();
  } else {
    audio = new Audio(
      document.getElementById("play-btn").getAttribute("data-value")
    );
    audio.play();
  }
};