import React, { Component, useEffect } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/auth/index";

const Logout = (props) => {
  const { logout } = props;
  const intl = useIntl();
  useEffect(() => {
    logout();
  }, []);
  return (
    <div className="page">
      <section>
        <h1>
          <FormattedMessage id="logout.title" />
        </h1>
        <p>
          <FormattedMessage id="logout.p1" />
        </p>
        <p>
          <FormattedMessage
            id="logout.p2"
            values={{
              CodePermLoginLink: (
                <a href={intl.formatMessage({ id: "error.status_401.p3_url" })}>
                  {intl.formatMessage({ id: "error.status_401.p3_link" })}
                </a>
              ),
            }}
          />
        </p>
      </section>
    </div>
  );
};

export default connect(null, actions)(Logout);
