import React from "react";
import { FormattedMessage } from "react-intl";
const Footer = ({ previousHandler, nextHandler, nextDisabled }) => {

  return (
    <div id="form-footer">
          <button
            id="previous-btn"
            className="btn btn-default unselectable"
            onClick={previousHandler}
          >
            <FormattedMessage id="app.global.button.previous" />
          </button>
          <button
            id="next-button"
            className={"unselectable btn btn-primary"}
            onClick={nextHandler}
            disabled={nextDisabled}
          >
            <FormattedMessage id="app.global.button.next" />
          </button>
        </div>
  );
}

export default Footer;