import React, { Component } from 'react';
import { connect } from "react-redux";
import * as fromAuthReducer from "../../redux/reducers/auth";
import * as actions  from "../../redux/actions/auth/index";
import { FormattedMessage } from "react-intl";
import LoadingScreen from "../helpers/loadingScreen";
import { Redirect } from 'react-router';
import { clearState } from "../../localStorage";

class TokenLogin extends Component {
  constructor(props) {
    super(props);

    clearState();

    const { logout, doTokenLogin } = this.props;

    this.state = { initialized: false}; 

    let token = this.props.match.params.token;
    logout().then(()=>doTokenLogin(token).then(()=>this.setState({initialized: true})));
  }

  render() {
    const { auth_error, auth_isFetching, auth } = this.props;


    if(auth_error && auth_error.response?.data?.error === "too_early"){
      return (
        <>
          <h1>
            <FormattedMessage id='code-perm-login.title.too-early' />
          </h1>
          <p>
            <FormattedMessage id='code-perm-login.text.too-early.p1' />
          </p>
          {auth_error.response?.data?.dt_debut && auth_error.response?.data?.dt_fin &&
          <p>
            <FormattedMessage id='code-perm-login.text.too-early.p2' values={{Start: auth_error.response.data.dt_debut, End: auth_error.response.data.dt_fin}} />
          </p>
          }
        </>
      );
    }else if(auth_error){
      return(
      <p>
        <FormattedMessage id='code-perm-login.text.no-time-slot' />
      </p>
      );
    }


    if(this.state.initialized && auth?.isLoggedIn){
      return <Redirect to="/" />
    }

    return <LoadingScreen />
  }
}

  function mapStateToProps(state) {
    return {
      auth: state.auth,
      auth_error: fromAuthReducer.getError(state.authStatus),
      auth_isFetching: fromAuthReducer.getIsFetching(state.authStatus),
    };
  }

  export default connect(mapStateToProps, actions)(TokenLogin);