import React, { Component } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

class LayoutProfessional extends Component {
  render() {
    const { testCompletedHandler } = this.props;
    return (
      <div className='page'>
        <section>
          <h1>
            <FormattedMessage id='questionnaire.completed.professional.title' />
          </h1>
          <p><FormattedMessage id='questionnaire.completed.professional.p1' /></p>
          <p>  
            <FormattedMessage id='questionnaire.completed.professional.p2'
              values={
                { 
                  SubmitForm: <strong>{this.props.intl.formatMessage({id: "questionnaire.completed.professional.p2_submit_form"})}</strong>
              }}
            />
          </p>
          <button className='btn btn-lg btn-primary' onClick={testCompletedHandler}>
            <FormattedMessage id='questionnaire.completed.professional.button.submit_questionnaire' />
          </button>
        </section>
      </div>
    );
  }
}

export default injectIntl(LayoutProfessional);
