import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import QuestionInstructions from "./QuestionInstructions";
import Question from "./Question";
import ProgressBar from "../components/ProgressBar";
import Footer from "./Footer";
import OptionItems from "./OptionItems";
import Explanation from "./Explanation";
import InstructionsPopOver from "../components/InstructionsPopOver";
import * as fromAssementTestQuestionReducer from "redux/reducers/assesmentTestQuestion";
import * as assesmentTestQuestionActions from "redux/actions/assesmentTestQuestion/index";
import { useSelector, useDispatch } from "react-redux";
import Timer from "../components/timer/Timer";
import { playAudio, stopAudio } from "../components/AudioPlayer";
import OpenAnswer from "./OpenAnswer";


const MultipleChoices = () => {

  const history       = useHistory();
  const dispatch      = useDispatch();
  const assesment     = useSelector((state) => state.assesment);
  const assesmentTest = useSelector((state) => state.assesmentTest);
  const isImgLoading  = useSelector((state) => {
    // This is used to show/hide the question if an image is loading
    // Images are for the time being "embeded" in the info data so this is not in use
    return fromAssementTestQuestionReducer.getIsImgLoading(
      state.assesmentTestQuestionStatus
    );
  });


  const firstUnAnsweredIndex = getFirstUnansweredIndex(assesmentTest.assesment_test_questions);
  const [index, setIndex] = useState(firstUnAnsweredIndex ?? assesmentTest.assesment_test_questions.length - 1);
  const [current, setCurrent] = useState(null);  // Here current refers to the current assesment_test_question
  const [mode, setMode] = useState(); // String. Mode of the current question.
  const [answers, setAnswers] = useState(); // Array. Options selected by the user.
  const [explanation, setExplanation] = useState(null); // String. Explanation for the REX questions [REX = Question with Explanation]
  const [prevEnabled, setPrevEnabled] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);

  useEffect(() => {
    if(index === null) return;
    if(!assesmentTest?.assesment_test_questions) return;

    const { assesment_test_questions, test } = assesmentTest;

    stopAudio();

    if(index >= assesment_test_questions.length){
      history.push("completed");
    }else if(index < 0) {
      history.push("/assesment-test");
    }else {
      setCurrent(assesment_test_questions[index]);
      setMode(assesment_test_questions[index].question.answer_definition.answer_mode_id);
      setAnswers(assesment_test_questions[index].answers ?? []);
      setExplanation(assesment_test_questions[index].answer_explanation);
      // If test has a Timer, do not allow to go back beyond the first question (test introduction)
      // which would stop the timer, giving the user more time to answer the questions
      setPrevEnabled(test.duration_max ? index > 0 : true);
    }
    
  }, [index, assesmentTest.assesment_test_questions]);

  
  /**
   * Handle option answer change
   * 
   * Save the answer and automatically go to the next question
   */
  useEffect(() => {
    if(!current) return;
    if (mode == "RE") {
      if(JSON.stringify(answers) === JSON.stringify(current.answers)) return;
      save(()=>{setIndex(i => i + 1);});
    }else{
      if(JSON.stringify(answers) === JSON.stringify(current.answers) && explanation === current.answer_explanation) return;
      save();
    }
  }, [answers]);


  /**
   * Handle button onLast
   * 
   * Save the answers and explanations and go to the last question or the first unanswered question
   * 
   * @returns void
   */
  const onLast = ()=>{
    save(()=>{setIndex(firstUnAnsweredIndex ?? assesmentTest.assesment_test_questions.length - 1)});
  };
    

  /**
   * Save the current question
   * 
   * @param {Function} callback
   */
  const save = async (callback) => {
    const question = assesmentTest.assesment_test_questions[index];
    if(JSON.stringify(answers) === JSON.stringify(question.answers) &&
      explanation === question.answer_explanation){ // No changes
        if(callback) await callback();
      return;
    }

    const { updateAssesmentTestQuestion } = assesmentTestQuestionActions;
    const id = current.id;
    const payload = {};
    if(mode === "RE"){
      payload.answers = answers;
    }
    else if (mode === "REX") {
      payload.answers = answers;
      payload.answer_explanation = explanation;
    }else if (mode === "OUV") {
      payload.answer_explanation = explanation;
    }
    
    await dispatch(updateAssesmentTestQuestion(id, payload))
    .then(() => {
      if(callback) callback();
    });
  };

  if(!current) return <></>;

  return (
    <>
      <div id="multiple-choices">
        <div id="header">
          <ProgressBar currentIndex={ index + 1} total={assesmentTest.assesment_test_questions.length} />
          <Timer saveQuestion={save} />
        </div>
        <div id="question-wrapper"
          // Images are for the time being "embeded" in the info data
          style={{
            visibility: isImgLoading
              ? "hidden"
              : "visible",
          }}
        >
          <QuestionInstructions current={current} />
          <Question current={current} playAudio={playAudio} />

          { (mode === "RE" || mode === "REX") &&
            <OptionItems
              answer={answers}
              onChange={(e) => {setAnswers([Number(e.target.value)]);}}
              current={current}
            >
              <Explanation current={current} value={explanation} onChange={(e)=>{setExplanation(e.target.value);}} />
            </OptionItems>
          }
          { mode === "OUV" &&
            <OpenAnswer current={current} answer={explanation} onChange={(e)=>{setExplanation(e.target.value);}} />
          }

        </div>
        <Footer
          onFirst={()=>{save(()=>{setIndex(0)})}}
          onPrevious={()=>{save(()=>{setIndex(i => i - 1)})}}
          onNext={()=>{save(()=>{setIndex(i => i + 1)})}}
          onLast={onLast}
          isFirstEnabled={index > 0}
          isPrevEnabled={prevEnabled}
          isNextEnabled={isAnswered(current)}
          isLastEnabled={isAnswered(current)}
          toggleInstructions={()=>{setShowInstructions(!showInstructions)}}
        />
      </div>

      <InstructionsPopOver
        show={showInstructions}
        assesment={assesment}
        assesmentTest={assesmentTest}
        toggleInstructions={()=>{setShowInstructions(!showInstructions)}}
      />
      
    </>
  );
};

/**
 * Finds the index of the first unanswered question in the given array of questions.
 * An unanswered question is defined as a question that does not have any answers or explanation.
 *
 * @param {Array} questions - The array of questions to search through.
 * @returns {number|null} - The index of the first unanswered question, or null if no unanswered question is found.
 */
const getFirstUnansweredIndex = (questions) => {
  const index = questions.findIndex((q)=>{
    if(q.question.answer_definition.answer_mode_id === "REX"){
      return !q.answers.length || !q.answer_explanation?.length;
    }else if(q.question.answer_definition.answer_mode_id === "OUV"){
      return !q.answer_explanation?.length;
    } // mode is RE
    return !q.answers.length
  });

  return index >= 0 ? index : null;
};

/**
 * Determines if an assessment test question is answered.
 *
 * @param {Object} assessmentTestQuestion - The assessment test question object.
 * @returns {boolean} - True if the question is answered, false otherwise.
 */
const isAnswered = (assesmentTestQuestion) => {
  const mode        = assesmentTestQuestion.question.answer_definition.answer_mode_id;
  const answers     = assesmentTestQuestion.answers;
  const explanation = assesmentTestQuestion.answer_explanation;

  if(mode == "REX"){ // Answer with choices AND text explanation
    return answers?.length > 0 && explanation?.length > 0 ? true : false;
  }else if(mode == "OUV"){ // Answer with text explanation
    return explanation?.length > 0 ? true : false;
  }else{ // mode is RE. Answer with choices
    return answers?.length > 0 ? true : false;
  }
}

export default MultipleChoices;
