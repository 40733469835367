import React from "react";
import { useTranslation } from "hooks/useTranslation";

const SortingButton = (props) => {
  const label = useTranslation("label", props.dropzone.answer_definition_choice);
  return (
    <button
      className="btn btn-primary"
      onClick={props.onClickFunction}
      value={props.value}
      disabled={props.disabled}
    >
      {label}
    </button>
  );
};

export default SortingButton;
