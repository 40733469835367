import * as actionCreators from "./actionCreators";

export const doTokenLogin = (token) => (dispatch, getState, api) => {
  dispatch(actionCreators.loginRequested(token));
  return new Promise(async (resolve, reject) => {
    await api.tokenLogin(token).then(
      (response) => {
        dispatch(actionCreators.loginSuccessful(response.data));
        resolve(response.data);
      },
      (error) => {
        dispatch(actionCreators.loginFailure(token, error));
        reject(error);
      }
    );
  });
};

export const doAssesmentLogin = (url) => (dispatch, getState, api) => {
  dispatch(actionCreators.loginRequested(url));
  return new Promise(async (resolve, reject) => {
    await api.assesmentLogin(url).then(
      (response) => {
        dispatch(actionCreators.loginSuccessful(response.data));
        resolve(response.data);
      },
      (error) => {
        dispatch(actionCreators.loginFailure(url, error));
        reject(error);
      }
    );
  });
};

export const doCodePermLogin = (codePerm) => (dispatch, getState, api) => {
  dispatch(actionCreators.loginRequested(codePerm));
  return new Promise(async (resolve, reject) => {
    await api.codePermLogin(codePerm).then(
      (response) => {
        dispatch(actionCreators.loginSuccessful(response.data));
        resolve(response.data);
      },
      (error) => {
        dispatch(actionCreators.loginFailure(codePerm, error));
        reject(error);
      }
    );
  });
};

export const logout = () => (dispatch, getState, api) => {
  /**
   * If the user is not logged in, we don't need to call the API
   */
  if(getState().auth.isLoggedIn === false){
    dispatch(actionCreators.logoutRequested());
    dispatch(actionCreators.logoutSuccessful());
    return;
  }

  dispatch(actionCreators.logoutRequested());
  return new Promise(async (resolve, reject) => {
    await api.logout().then(
      (response) => {
        dispatch(actionCreators.logoutSuccessful(response));
        resolve(response);
      },
      (error) => {
        dispatch(actionCreators.logoutFailure(error));
        reject(error);
      }
    );
  });
};
