import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "hooks/useTranslation";

const Consigne = (props) => {
  const assesmentTest = useSelector((state) => state.assesmentTest);
  const consigne = useTranslation("instructions", assesmentTest.test.test_baskets[0].answer_definition);
  return (
      <h4 className='unselectable'>{consigne}</h4>
  );
};

export default Consigne;