import React, {useEffect, useState} from "react";
import * as AUDIENCES from "constants/audiences";
import { withRouter } from "react-router-dom";
import * as assesmentActions from "redux/actions/assesment/index";
import * as assesmentTestActions from "redux/actions/assesmentTest/index";
import { useSelector, useDispatch } from "react-redux";
import LayoutProfessional from "./LayoutProfessional";
import LayoutAcademic10 from "./LayoutAcademic10";
import LayoutCollege20 from "./LayoutCollege20";
import LoadingScreen from "components/helpers/loadingScreen";

function Completed(props) {
  const dispatch = useDispatch();
  const assesment = useSelector((state) => state.assesment);
  const { fetchAssesment } = assesmentActions;
  const assesmentTest = useSelector((state) => state.assesmentTest);
  const { fetchAssesmentTest, markAssesmentTestAsCompleted } = assesmentTestActions;
  const [loading, setLoading] = useState(true);
  
  /**
   * Fetch assesment and assesment-test.
   * and save 'started_at' if not already started.
   * 
   */
  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    dispatch(fetchAssesmentTest(props.match.params.id))
      .then((resp) => {
        dispatch(fetchAssesment(resp.assesment_id))
          .then(() => {
            setLoading(false);
          });
    });
    
  }, [dispatch]);


  useEffect(() => {
    if(assesmentTest.completed_at){
      props.history.push("/assesment-test"); // Redirect if already completed
    }
  }, [assesmentTest]);



  const complete = () => {
    const id = props.match.params.id;
    const payload = null;
    dispatch(markAssesmentTestAsCompleted(id, payload))
      .then(() => {
        props.history.push("/assesment-test");
    });
  }

  if (loading) {
    return <LoadingScreen />;
  }

  if (assesment.evaluation_session.audience_type_id === AUDIENCES.ACADEMIC_PRIMARY) {
    return <LayoutAcademic10 testCompletedHandler={complete} />;
  }
  if (assesment.evaluation_session.audience_type_id === AUDIENCES.ACADEMIC_COLLEGE) {
    return <LayoutCollege20 testCompletedHandler={complete} />;
  }

  return <LayoutProfessional testCompletedHandler={complete} />;
}

export default withRouter(Completed);
