import React, {useState, useEffect} from "react";
import { Redirect } from "react-router-dom";
import * as assesmentTestActions from "redux/actions/assesmentTest/index";
import * as assesmentActions from "redux/actions/assesment/index";
import MultipleChoices from "./multiplechoices/MultipleChoices";
import CardSorting from "./cardSorsting/CardSorting";
import LoadingScreen from "../../helpers/loadingScreen";
import FetchError from "../../error/FetchError";
import BlockingScreen from "../../helpers/blockingScreen";
import { useSelector, useDispatch } from "react-redux";

const Questionnaire = (props) => {

  const dispatch = useDispatch();
  const assesment = useSelector((state) => state.assesment);
  const assesmentStatus = useSelector((state) => state.assesmentStatus);
  const {isFetching: assesment_fetching, error: assesment_error} = assesmentStatus;
  const assesmentTest = useSelector((state) => state.assesmentTest);
  const assesmentTestStatus = useSelector((state) => state.assesmentTestStatus);
  const {isFetching: assesmentTest_fetching, error: assesmentTest_error} = assesmentTestStatus;
  const assesmentTestQuestionStatus = useSelector((state) => state.assesmentTestQuestionStatus);
  const {error: assesmentTestQuestion_error} = assesmentTestQuestionStatus;
  
  const [initializing, setInitializing] = useState(false);

  /**
   * Fetch assesment and assesment-test.
   * and save 'started_at' if not already started.
   * 
   */
  useEffect(() => {
    let isMounted = true; // flag to check if component is still mounted
    setInitializing(true);
    window.scrollTo(0, 0);
    dispatch(assesmentActions.fetchAssesment(assesment.id));
    dispatch(assesmentTestActions.fetchAssesmentTest(props.match.params.id))
      .then((resp)=>{
        if(!isMounted) return; // skip if component is unmounted
        setInitializing(false);
        if(!resp.started_at) {
          const payload = { started_at: new Date() };
          dispatch(assesmentTestActions.updateAssesmentTest(props.match.params.id, payload));
        }
      });

    return () => { isMounted = false }; // cleanup function
  }, []);

  if (assesment_error) {
    return <FetchError error={assesment_error} redirect={true} />;
  }
  if (assesmentTest_error) {
    return <FetchError error={assesmentTest_error} redirect={true} />;
  }
  if (assesmentTestQuestion_error) {
    return <FetchError error={assesmentTestQuestion_error} redirect={true} />;
  }
  
  if (initializing || assesment_fetching || assesmentTest_fetching) {
    return <LoadingScreen />;
  }
  
  if (assesmentTest.completed_at) {
    return <Redirect to={"/assesment-test"} />;
  }

  if (!assesment.consent) {
    return <Redirect to={"/consentement"} />;
  }

  if(initializing) {
    return <LoadingScreen />;
  }
  if(!assesmentTest.test) {
    return <LoadingScreen />;
  }
  if(assesmentTestStatus.isFetching || assesmentTestQuestionStatus.isFetching || assesmentStatus.isFetching) {
    return <LoadingScreen />;
  }

  return (
    <div id="test">

      {assesmentTest.test.test_type == "RE" &&
        <MultipleChoices />
      }

      {assesmentTest.test.test_type == "PA" &&
        <CardSorting />
      }

      { assesmentTestQuestionStatus.isUpdating && <BlockingScreen /> }
    </div>
  );
  
}

export default Questionnaire;